import React, { PureComponent } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Select from 'react-select'
import classNames from 'classnames'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import PullToRefresh from 'pulltorefreshjs'
import { prepend, omit, uniq, propEq, find, map, concat, path, contains, flip, keys, pickBy, pipe, isEmpty, not, test, filter, prop, nth, pluck } from 'ramda'
import bindAllActions from '../../common/bindAllActions'
import {
  AlertBanner,
  SearchBar,
  DevicesHeader,
  DeviceDataTableWrap,
  DeviceRealtimeDashboard,
  WebcamArchive,
  LastUpdated,
  WebcamImage
} from '.'
import {
  MapWithForecast,
  MinMaxBtns,
  FavStar,
  SiteAlerts,
  DeviceChooser
} from '../../components'
import { userCanSocialPost, getWebcamArchives, getDateTzForDevice, hasVideo, showV3, isIos, generalModalStyle, currentDeviceHasSummaries, isLoggedIn, getDeviceSetting, log, WIDGET_CONFIG, getTheDevice, shouldGetDevice, getUserSetting, deviceIsMine, widgetExpanded, handleWidgetChange, getDeviceLabel, getDeviceSlug, PRIVATE_PARAMS, hasWebcam, isAdmin, hasSocial, getUrlQueryParam, deviceHasEnhancedCam, pathsChanged, canAccessOldData, yearsUserCanAccess } from '../../common/ambient'
import { Avatar } from '../user'
import { MyTimeline } from '../social'
import { deviceCamTierI } from '../payment'

// function showV3() {
//   return false
// }

class DashboardWrap extends PureComponent {
  static propTypes = {
    device: PropTypes.object,
    history: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      deviceI: 0,
      lastMac: false,
      videoPlaying: false,
      videoLoading: false,
      top: null,
      hideKeys: [],
      alertObj: {},
      pip: 'map'
    }

    this.alertObjFetched = false
    this.handleSearch = ::this.handleSearch
    this.doRefresh = ::this.doRefresh
    this.fetchSummaries = ::this.fetchSummaries
    this._dashHeader = ::this._dashHeader
    this._isTabActive = ::this._isTabActive
    this._onScroll = ::this._onScroll
  }
  componentDidMount() {
    const { deviceActions } = this.props
    deviceActions.setThing('mapLayer', '')
    deviceActions.focusDevice(null)
    deviceActions.hoverDevice(null)
    this.ptr = PullToRefresh.init({
      onRefresh: this.doRefresh,
      distReload: 0
    })
    if (window.pageYOffset < 1) {
      document.body.classList.add('ptr--top') // add ptr class to body the very first time
    }
    window.addEventListener('scroll', this._onScroll)
    this._onScroll()
    this._checkDashboardDevice()
    this.fetchSummaries()
    this._checkPip()
    this._resizeMap()
  }
  fetchSummaries() {
    const { lastMac } = this.state
    const { user, device, deviceActions } = this.props
    const { deviceSummaries, devices, deviceI, fetchDeviceDataKeysPending } = device
    const dataKey = 'deviceSummaries'
    const theDevice = this._getDevice()
    // new device
    if (user.userChecked && theDevice && theDevice.macAddress !== lastMac) {
      // reset map layer
      deviceActions.setThing('mapLayer', '')

      const macAddress = theDevice.macAddress
      this.setState({
        lastMac: macAddress
      }, function() {
        deviceActions.fetchDeviceData({
          macAddress,
          dataKey,
          start: Date.now() - 1000 * 60 * 60 * 24 * 365 * yearsUserCanAccess(user),
          stats: 'summary'
        })
          .then(res => {
            // check for bad devices & fix them
            // if (theDevice 
            //   && !path(['lastData', 'tz'], theDevice)
            // ) {
            //   deviceActions.patch(theDevice._id, { tz: theDevice.tz }) // set timezone again
            //   /* .then(() => deviceActions.patch(theDevice._id, { summaryMigrated: null })) // re-run migration */
            // }
          })
      })
    }
  }
  componentWillUnmount() {
    if (this.ptr) {
      this.ptr.destroy()
    }
    window.removeEventListener('scroll', this._onScroll)
  }
  _onScroll(evt) {
    const { commonActions } = this.props
    const { top } = this.state
    const newTop = window.scrollY < 10
    if (newTop === top && top !== null) return
    commonActions.toggleAppClass('scroll-top', newTop)
    this.setState({
        top: newTop
    })
  }

  _userRecent() {
    const recent = getUserSetting('recent')(this.props.user)
    return recent || false
  }
  _checkDashboardDevice() {
    const { device, deviceActions, match, social, user, history } = this.props
    const { getDeviceFailures, dashboardDevice, devices, fetchDevicePending } = device
    const { userChecked } = user
    const { favs } = social
    if (dashboardDevice) return
    // this process was copied from DeviceChooser 5.5.22 💃🏽
    const slug = path(['params', 'key'], match) 
    if (slug) {
      const myDevice = devices && devices.find(d => getDeviceSlug(d) === slug)
      if (myDevice) {
        deviceActions.setDashboardDevice(myDevice)
      } else if (!fetchDevicePending) {
        deviceActions.fetchDevice({ 'public.slug': slug })
          .then(res => {
            deviceActions.clearFetchedDevices()
            if (res.data[0]) {
              deviceActions.setDashboardDevice(res.data[0])
            } else if (userChecked) {
              history.push('/dashboard')
            }
          })
      }
      return
    }
    // default to their most recent 
    if (this._userRecent() && this._userRecent()[0]) {
      // if this is their device, we need to use the current state of the device
      let recentDevice = this._userRecent()[0]
      const myDevice = devices && find(propEq('macAddress', recentDevice.macAddress), devices)
      return deviceActions.setDashboardDevice(myDevice || recentDevice)
    }
    // or their first favorite
    if (favs) {
      const favToChoose = pipe(
        pluck('to'),
        filter(d => !contains(d._id, getDeviceFailures)), // remove devices no longer public
        nth(0)
      )(favs)
      if (favToChoose) {
        return deviceActions.setDashboardDevice(favToChoose)
      }
    }
    // or their first device
    if (devices && devices[0]) {
      deviceActions.setDashboardDevice(devices[0])
    }
  }
  _hasEnhancedCam() {
    return deviceHasEnhancedCam(this._getDevice())
  }
  componentDidUpdate(prevProps) {
    const { history, device, deviceActions, location } = this.props
    const { dashboardDevice } = device
    this._checkDashboardDevice()
    this.fetchSummaries()
    if (dashboardDevice && shouldGetDevice(device, dashboardDevice._id, 1)) {
      deviceActions.getDevice(dashboardDevice._id)
    }
    this._fsWebcam()
    this._checkPip()
    if (pathsChanged(prevProps, this.props, [['match', 'params']])) {
      this._resizeMap()
    }

    // check enhanced cam tabs
    const dev = this._getDevice()
    if ((this._isTabActive('cam-charts') || this._isTabActive('cam-graphs')) && dev && !this._hasEnhancedCam()) {
      history.push(`/dashboard/${getDeviceSlug(dev)}`)
    } else if (location.pathname === '/dashboard' && dev && getDeviceSlug(dev)) {
      history.replace(`/dashboard/${getDeviceSlug(dev)}`)
    }

    if (!this._isTabActive('cam-charts')) {
      this.setState({ markerTime: null })
    }
  }
  _checkPip() {
    const queryPip = getUrlQueryParam(this.props.location, 'pip')
    const { pip } = this.state
    let newPip
    // honor query string
    if (queryPip && queryPip !== pip && ['map', 'webcam'].includes(queryPip)) {
      newPip = queryPip
    }
    const dev = this._getDevice()
    // has webcam but no location
    if (dev && hasWebcam(dev) && this._noLoc()) {
      newPip = 'webcam'
    }
    // last device had webcam but this one doesn't
    if (dev && !hasWebcam(dev) && pip === 'webcam') {
      newPip = 'map'
    }
    if (newPip) {
      this.setState({ pip: newPip })
      if (queryPip && newPip !== queryPip) {
        this._doPip(newPip)
      }
      this._resizeMap()
    }
  }
  _resizeMap() {
      if (this._map) {
        setTimeout(() => {
          this._map.resize()
        }, 100)
      }
  }
  _fsWebcam() {
    const { userActions, location } = this.props
    const dev = this._getDevice()
    if (dev && /show=webcam/.test(location.search) && !this._modalShown) {
      this._modalShown = true
      const archiveVideoObj = this._archiveVideoObj()
      userActions.doModal({ type: 'webcam', data: archiveVideoObj ? archiveVideoObj.dev : dev })
    }
  }
  _getDevice() {
    const { user, device, social } = this.props
    const { dashboardDevice } = device
    let theDevice = dashboardDevice 
    const favs = getUserSetting('favs')(user) || {}
    if (theDevice && favs[theDevice.macAddress]) {
      theDevice = Object.assign(
        {}, 
        theDevice, 
        { settings: Object.assign({}, theDevice.settings, favs[theDevice.macAddress].settings) }
      ) 
    }
    // restrict un-owned devices - used for widget labels
    if (theDevice && !deviceIsMine(device, theDevice._id) && !favs[theDevice.macAddress]) {
      theDevice = Object.assign({}, theDevice, { restrict: true })
      theDevice.settings = theDevice.settings || {}
      // and keep the map open if the webcam isn't open
      if (!path(['settings', 'dashboard-top', 'expanded'], theDevice)) {
        theDevice.settings['dashboard-top'] = {
          expanded: true
        }
      }
    }
    return theDevice
  }
  handleSearch(val) {
    const device = this._getDevice()
    if (val === '') {
      this.setState({
        hideKeys: []
      })
      return
    }
    // default keywords
    const testString = test(new RegExp('^' + val, 'i'))
    const hasKeyword = pipe(
      prop('keywords'),
      keywords => keywords || [],
      filter(testString),
      isEmpty,
      not
    )
    const allKeys = keys(WIDGET_CONFIG)
    const foundKeys = keys(pickBy(hasKeyword, WIDGET_CONFIG))
    
    // widget names
    const widgetsWithCustomTitles = pickBy(path(['title']), device.settings || {})
    const cleanSubKeys = (key) => {
      // custom sensor names are stored in temp1f, temp2f
      if (/\df/.test(key)) {
        return 'sensors'
      }
      // custom relay names are stored in relay1, relay2
      return key.replace(/(\d+)$/, 's')
    }
    const customFoundKeys = map(cleanSubKeys, keys(pickBy(pipe(prop('title'), testString), widgetsWithCustomTitles)))
    const allFoundKeys = concat(foundKeys, customFoundKeys)

    const hideKeys = filter(pipe(flip(contains)(allFoundKeys), not), allKeys)
    this.setState({
      hideKeys
    })
  }
  doRefresh() {
    const { deviceActions } = this.props
    deviceActions.manualRefresh()
    deviceActions.fetchDevices()
  }
  _getUserSetting(key) {
    return getUserSetting(key)(this.props.user) || {}
  }
  _isTabActive(val) {
    const { tab = 'tiles' } = this.props.match.params
    if (!val) return tab
    return tab === val
  }
  _dashHeader() {
    const { match, user, userActions, device, history } = this.props
    const { params } = match
    const { key, tab } = params
    const dev = this._getDevice()
    const showSocial = !isLoggedIn(user) || !deviceIsMine(device, dev._id) || dev.public
    const canPost = hasSocial(user) && dev.lastData && path(['info', 'coords'], dev) && deviceIsMine(device, dev._id) && dev.public
    return [
      <div key='dash-header' className="dashboard-header" >
        <div className="device-info">
          <Avatar u={dev.unitSetting} />
          <DeviceChooser 
            onChange={(newDevice, evt) => {
              // if (newDevice && dashboardDevice && dashboardDevice._id === newDevice._id) return
              // if (!dashboardDevice) return
              // deviceActions.setDashboardDevice(newDevice)
              const slug = getDeviceSlug(newDevice) 
              if (slug) {
                let tab = ''
                const dotLayer = path(['layer'], evt)
                if (dotLayer) {
                  tab = dotLayer === 'social' ? '/social' : '/tiles'
                } else if (params.tab) {
                  tab = `/${params.tab}` 
                }
                history.replace('/dashboard/' + slug + tab + (params.id ? `/${params.id}` : ''))
              } else {
                history.push('/dashboard')
              }
            }}
          />
        </div>
        <div className="tabs">
          <Link to={`/dashboard/${getDeviceSlug(dev)}/tiles`} className={classNames('widgets', { active: this._isTabActive('tiles') })}>
            <span>Tiles</span>
          </Link>
          <Link to={`/dashboard/${getDeviceSlug(dev)}/graphs`} className={classNames('graphs', { active: this._isTabActive('graphs') })}>
            <span>Charts &&nbsp;Graphs</span>
          </Link>
          {showSocial ? <Link to={`/dashboard/${getDeviceSlug(dev)}/social`} className={classNames('social', { active: this._isTabActive('social') })}>
            <span>
              Social
            </span>
          </Link> : null}
          {this._hasEnhancedCam() && <>
            <Link to={`/dashboard/${getDeviceSlug(dev)}/cam-charts`} className={classNames('cam-charts', { active: this._isTabActive('cam-charts') })}>
              <span>Weather Cam</span>
            </Link>
            <Link to={`/dashboard/${getDeviceSlug(dev)}/cam-library`} className={classNames('cam-library', { active: this._isTabActive('cam-library') })}>
              <span>Library</span>
            </Link>
          </>}
        </div>
      </div>,
    ]
  }
  // no top map
  _noLoc() {
    const dev = this._getDevice() 
    return !path(['info', 'coords', 'coords'], dev)
    return !path(['info', 'coords', 'coords'], dev) || !widgetExpanded('map', dev)
  }
  // no top at all
  _noTop() {
    const dev = this._getDevice() 
    return this._noLoc() && !hasWebcam(dev)
  }
  _showWebcamTop() {
    const dev = this._getDevice() 
    return hasWebcam(dev) && widgetExpanded('webcam', dev)
  }
  _topExpanded() {
    const dev = this._getDevice() 
    return widgetExpanded('dashboard-top', dev) !== false 
  }
  // if we're looking at an archive video return everything we need
  _archiveVideoObj() {
    const { match } = this.props
    const { params } = match
    const dev = this._getDevice()
    const archives = getWebcamArchives(dev)
    // alter the device if it's a different day
    if (params.id) {
      const archiveVideoI = archives.findIndex(obj => params.id === obj.date.format('YYYYMMDD'))
      const archiveVideo = archives[archiveVideoI]
      if (archiveVideo) {
        return {
          archiveVideo,
          archiveVideoI,
          dev: {
            ...dev,
            info: {
              ...dev.info,
              video: archiveVideo.url,
              webcam: archiveVideo.url.replace('.mp4', `-sunrise.jpg`)
            },
            webcam: {
              ...dev.webcam,
              video: archiveVideo.url
            }
          }
        }
      }
    }
  }
  // big map or webcam
  _top() {
    const { location, match, history, userActions, user, deviceActions, device } = this.props
    const { pip, videoPlaying } = this.state
    const { params } = match
    const { dashboardDevice } = device
    let dev = this._getDevice() 
    const show = []
    const noLoc = this._noLoc()
    const widgetChange = handleWidgetChange(userActions, user, device, dev, deviceActions)
    if (!this._restrict()) {
      show.push(
        <a key='top-toggle' className='top-toggle' onClick={() => {
          widgetChange({ expanded: !this._topExpanded() }, 'dashboard-top')
        }} />
      )
    }
    show.push(
      pip === 'map' & !this._isTabActive('cam-charts') && !this._isTabActive('cam-library') ? <Link key='full-screen-map' to='/' className='full-screen' /> : <a key='full-screen-webcam' className='full-screen' onClick={() => {
        this._modalShown = false
        history.push({ search: '?show=webcam', pathname: location.pathname })
        this._fsWebcam()
      }} />
    )
    show.push(
      <MapWithForecast 
        className={classNames({
          'no-loc': noLoc 
        })}
        key="map"
        isDashboard  
        onMapLoad={map => this._map = map}
        onDeviceChange={(newDevice, evt) => {
          if (newDevice && dashboardDevice && dashboardDevice._id === newDevice._id) return
          if (!dashboardDevice) return
          deviceActions.setDashboardDevice(newDevice)
          const slug = getDeviceSlug(newDevice) 
          if (slug) {
            let tab = ''
            const dotLayer = path(['layer'], evt)
            if (dotLayer) {
              tab = dotLayer === 'social' ? '/social' : '/tiles'
            } else if (params.tab) {
              tab = `/${params.tab}` 
            }
            history.replace('/dashboard/' + slug + tab + (params.id ? `/${params.id}` : ''))
          } else {
            history.push('/dashboard')
          }
        }}
      />
    )
    if (hasWebcam(dev)) {
      let playerProps = {}
      if (hasVideo(dev)) {
        // only play if pip is webcam
        if (pip === 'webcam') {
          playerProps = {
            light: false,
            playVideo: true,
            controls: false,
            playsinline: true,
            playing: true
          }
        }
        // aw.tv 
        // _____________
        // |  .----.  o|
        // | |      | o| 
        // | |      | o|
        // |__`----`___|
        //  `         `
        if(this._isTabActive('cam-charts') || this._isTabActive('cam-library')) {
          playerProps.light = false
          playerProps.playVideo = true
          playerProps.playsinline = true
          playerProps.playing = videoPlaying
          playerProps.controls = true
          playerProps.onPlay = () => this.setState({ videoPlaying: true })
          playerProps.onPause = () => this.setState({ videoPlaying: false })
          const archiveVideoObj = this._archiveVideoObj()
          let chosenArchiveDay = 0
          let archives = []
          if (archiveVideoObj) {
            dev = archiveVideoObj.dev
            chosenArchiveDay = archiveVideoObj.archiveVideoI + 1
            archives = getWebcamArchives(dev)
          }
          if (this._isTabActive('cam-charts')) {
            playerProps.config = {
              file: {
                attributes: {
                  crossOrigin: 'anonymous'
                },
                tracks: [
                  {
                    kind: 'chapters', 
                    src: hasVideo(dev).replace(/mp4$/, 'vtt').replace('https:', window.location.protocol), 
                    srcLang: 'en', 
                    default: true
                  }
                ]
              }
            }
            playerProps.progressInterval = 100
            playerProps.onReady = (video) => {
              this._video = video
              video.getInternalPlayer().textTracks[0].addEventListener('cuechange', e => {
                if (!video.getInternalPlayer()) return
                const track = video.getInternalPlayer().textTracks[0]
                if (track) {
                  const cue = track.activeCues[0]
                  if (cue) {
                    this.setState({
                      markerTime: parseInt(cue.text, 10)
                    })
                  }
                }
              })
            }
          }
          // day chooser
          if(archives.length > 0) {
            const options = prepend({
              label: 'Today',
              value: 0,
              id: ''
            }, archives.map((obj, i) => ({
              label: obj.date.format('M/D/YYYY'),
              value: i + 1,
              id: obj.date.format('YYYYMMDD')
            })))
            show.push(
              <Select
                key='archive-select-container'
                className='archive-select-container'
                classNamePrefix='archive-select'
                isSearchable={false}
                options={options}
                value={options[chosenArchiveDay]}
                onChange={selected => {
                  history.push(`/dashboard/${getDeviceSlug(dev)}/${this._isTabActive()}/${selected.id}`)
                }}
              />
            )
          }
        }
      }
      show.push(
        <WebcamImage 
          key={`webcam-${dev.macAddress}-${this._isTabActive('cam-charts') ? 'charts' : ''}`}
          currentDevice={dev} 
          {...playerProps}
        />
      )
      if (!this._noLoc()) {
        show.push(
          <a
            key='pip'
            className='pip' 
            onClick={() => {
              const param = pip === 'map' ? 'webcam' : 'map'
              this._doPip(param)
            }}
           />
        )
      }
    }
    return <div className={classNames("dashboard-top expanded")} key="top">{show}</div>
  }
  _doPip(newPip) {
    const { pip } = this.state
    const { location, history } = this.props
    const regex  = new RegExp(`&?pip=${pip}`)
    const search = location.search.replace(regex, ``)
    history.push({ search: search && search !== '?' ? search + `&pip=${newPip}` : `?pip=${newPip}`, pathname: location.pathname })
  }
  _restrict() {
    const { user, device } = this.props
    const dev = this._getDevice() 
    const favs = getUserSetting('favs')(user) || {}
    return dev && !favs[dev.macAddress] && !deviceIsMine(device, dev._id)
  }
  _socialTab() {
    const { params } = this.props.match
    const dev = this._getDevice() 
    return <MyTimeline postId={params.id} currentDevice={dev} key="social" />
  }
  render() {
    const { alertActions, user, device, userActions, match } = this.props
    const { hideKeys, pip, markerTime, videoPlaying, videoLoading, top } = this.state
    const { params } = match
    const dev = this._getDevice() 

    const dashPieces = []
    const hasLoc = path(['info', 'coords', 'coords'], dev) 
    const noLoc = this._noLoc()


    if (dev) {
      dashPieces.push(this._dashHeader())
      dashPieces.push(this._top())
      dashPieces.push(<SiteAlerts alertActions={alertActions} user={user} device={device} key="site-alerts" />)
      if (this._isTabActive('tiles')) {
        dashPieces.push(
          <div key="last-updated" className="countdown-wrap">
            <LastUpdated currentDevice={dev} />
          </div>
        )
        dashPieces.push(
          <DeviceRealtimeDashboard key="drd" currentDevice={dev} hideKeys={hideKeys} userActions={userActions} />
        )
      } else if (this._isTabActive('graphs') || this._isTabActive('cam-charts')) {
        const graphHideKeys = deviceIsMine(device, dev._id) ? hideKeys : uniq(concat(hideKeys, PRIVATE_PARAMS))
        let dates = null
        if (this._isTabActive('cam-charts') && params.id) {
          const day = moment.tz(params.id, 'YYYYMMDD', path(['tz', 'name'], dev))
          dates = [day.clone().startOf('day'), day.clone().endOf('day')]
        }
        dashPieces.push(
          <DeviceDataTableWrap 
            key={`ddtw-${this._isTabActive()}`}
            onGraphClick={evt => {
              if (this._video) {
                console.log(this._video)
                const track = this._video.getInternalPlayer().textTracks[0]
                if (track) {
                  let cue
                  for (let i = 0; i < track.cues.length; i++) {
                      if (evt.timestamp <= parseInt(track.cues[i].text, 10)){
                        cue = track.cues[i]
                        break
                      }
                  }
                  if (cue) {
                    this._video.seekTo(cue.startTime, 'seconds')
                  }
                }
                if (!videoPlaying) {
                  this.setState({ videoPlaying: true })
                }
              }
            }}
            shortGraphs={this._isTabActive('cam-charts')}
            dates={dates}
            markerTime={markerTime} 
            hideKeys={graphHideKeys} 
            macAddress={dev.macAddress} 
          />
        )
      } else if (this._isTabActive('social')) {
        dashPieces.push(this._socialTab())
      } else if (this._isTabActive('cam-library')) {
        dashPieces.push(
          <div key="cam-library" className="cam-library">
            <WebcamArchive currentDevice={dev} />
          </div>
        )
      }
    }
    return (
      <div ref={el => this.el = el} className={classNames(
        'device-dashboard-wrap', 
        'top-' + pip, 
        path(['match', 'params', 'tab'], this.props) || 'tiles', 
        {
          'video-playing': videoPlaying,
          'video-loading': videoLoading,
          'mine': path(['_id'], dev) && deviceIsMine(device, dev._id),
          restrict: this._restrict(),
          'no-loc': dev && noLoc,
          'no-top': this._noTop(),
          'no-maps': !hasLoc,
          'no-dash-top': !this._topExpanded(),
          top
        })}>
        <div className="device" >
          {dashPieces}
        </div>
        {userCanSocialPost(user, device) &&
          <a
            className='create-post-btn'
            onClick={() => {
              userActions.doModal({
                type: 'create-post'
              })
            }}
          />}
      </div>
    );
  }
}
export default bindAllActions(withRouter(DashboardWrap))

DashboardWrap.displayName = 'DashboardWrap'
