import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

export default class TermsOfUse extends PureComponent {
  static propTypes = {

  };

  render() {
    return (
      <div className="component-terms-of-use">
        <pre>
        Last updated: October 21, 2022<br/><br/>

These Terms of Service and End-User License Agreement ("Terms") govern your relationship with Nielsen-Kellerman Co. and/or affiliated subsidiary companies (collectively, "NK", "we", "us" or "our") with regards to your use and interaction with any devices or products sold by, websites operated by, mobile applications published by, and/or social media groups or pages ("Social Media") administered by NK, together with any software or service(s) related to any of the foregoing (all of the foregoing, collectively, the "Services"). As of the date of these Terms, the Services include, but are not limited to, the following:<br/><br/>

Devices (the "Devices"):<br/><br/>
Kestrel® Weather and Environmental Instruments, Meters and DROP Data Loggers<br/>
KestrelMetTM Weather Stations and Sensors<br/>
Ambient® Weather Stations and Environmental Sensors<br/>
RainWise® Weather Stations, Rain Gauges and Environmental Sensors<br/>
Kestrel Ballistics® Meters, Displays and Timers<br/>
MagnetoSpeed® Chronographs, Barrel Coolers and Target Hit Indicators<br/>
Nielsen-Kellerman®/NK® Sports Meters, Watches, Amplifiers, Speakers, and Wiring<br/>
Blue Ocean Rugged Megaphones®<br/><br/>

Sites (the "Sites"):<br/><br/>
<a href="https://kestrelinstruments.com" target="_blank">kestrelinstruments.com</a><br/>
<a href="https://kestrelmeters.com" target="_blank">kestrelmeters.com</a><br/>
<a href="https://heatstress.com" target="_blank">heatstress.com</a><br/>
<a href="https://kestrelmet.com" target="_blank">kestrelmet.com</a><br/>
<a href="https://ambientweather.com" target="_blank">ambientweather.com</a><br/>
<a href="https://ambientweather.net" target="_blank">ambientweather.net</a><br/>
<a href="https://rainwise.com" target="_blank">rainwise.com</a><br/>
<a href="https://rainwise.net" target="_blank">rainwise.net</a><br/>
<a href="https://kestrelballistics.com" target="_blank">kestrelballistics.com</a><br/>
<a href="https://hornadykestrel.com" target="_blank">hornadykestrel.com</a><br/>
<a href="https://magnetospeed.com" target="_blank">magnetospeed.com</a><br/>
<a href="https://nksports.com" target="_blank">nksports.com</a><br/>
<a href="https://blueoceanmegaphone.com" target="_blank">blueoceanmegaphone.com</a><br/>
<a href="https://nkhome.com" target="_blank">nkhome.com</a><br/><br/>

Applications (the "Apps"):<br/><br/>
Kestrel® LiNK<br/>
Kestrel® LiNK Ballistics®<br/>
NK® LiNK Logbook<br/>
Ambient Weather®<br/>
AWNet<br/>
MagnetoSpeed® XFR<br/>
MagnetoSpeed® T1000 Tuner<br/>

These Terms apply to the use of all Services regardless of whether they post a link to these Terms. We make our Services available to you subject to these Terms, which include our <a href="https://nkhome.com/nk/legal/privacy" target="_blank">Privacy Policy</a>, other policies published on or in the Services, and any guidelines, rules, additional terms or disclaimers that may be posted and updated on the Services or on notices we send you.  Your use of our Devices is also subject to any additional terms and conditions that are included with the Device at the time of purchase (including, without limitation, any written materials that accompany the Device).<br/><br/>

Please read these Terms carefully before proceeding! By clicking the "I Agree" button; accessing, uploading, downloading, posting to or using any Site, App and/or Social Media; or by indicating your assent by creating an account, clicking "sign up" or any similar mechanism; you are agreeing to be bound by these Terms. If you do not agree to these Terms, do not click on the "I Agree" button or download or use the Service.<br/><br/>

YOU ACKNOWLEDGE AND AGREE THAT THESE TERMS LIMIT OUR LIABILITY AND THAT YOU ARE RELEASING US FROM VARIOUS CLAIMS IN SECTION 11 BELOW. THESE TERMS ALSO CONTAIN A BINDING ARBITRATION PROVISION IN SECTION 17 THAT AFFECT YOUR RIGHTS UNDER THESE TERMS WITH RESPECT TO THE SERVICES.<br/><br/>

Overview<br/><br/>

Privacy: It's important so please review our <a href="https://nkhome.com/nk/legal/privacy" target="_blank">Privacy Policy</a>.<br/>
<a target="_blank" href="https://ambientweather.com/#cancel">Cancelation</a>: It's easy for you to cancel and deactivate. We also reserve the right to cancel your account for inappropriate conduct and, notwithstanding anything in these Terms or elsewhere to the contrary, we make no guaranty that we will continue to provide or support any of our Services.<br/>
<a target="_blank" href="https://ambientweather.com/#content">Content</a>: It's yours, but you are giving us a right to use it, as further described below.<br/>
<a target="_blank" href="https://ambientweather.com/#contact">Feedback</a>: We like your suggestions and feedback, and by offering any suggestions or feedback to us, you agree that we can use it without obligation to you.<br/>
<a target="_blank" href="https://ambientweather.com/#warranty">No Warranty</a>: We provide our apps and services "As-Is", without warranty and your use is at your own risk.<br/>
<a target="_blank" href="https://ambientweather.com/#arbitration">Arbitration</a>: You agree to arbitrate disputes between us.<br/>
<a target="_blank" href="https://ambientweather.com/#contact">Contact</a>: We want you to be able to reach us if you have questions or need help.<br/>

<br/><br/><br/>1) Using our Services<br/><br/>

If you access or use the Services on behalf of your employer or another entity, you represent and warrant that: (i) you have full legal authority to bind your employer, or the applicable entity, to these terms; (ii) you have read and understand these Terms; and (iii) you agree, on behalf of the party that you represent, to these Terms. If you don't have the legal authority to bind your employer or the applicable entity, you may not accept these Terms on their behalf or access or use the Services on their behalf.<br/>

If you access or use the Services on behalf of anyone else, including an employer, a company or a club, you represent that you have authority to accept these Terms on that person's behalf and that these Terms are fully binding on such person. In such case, the term "you" means to such other person.<br/>

You must be 13 years old or older to use the Services. By creating an account, registering or accessing, using or uploading or downloading any information or materials to or from the Services, you represent that you are 13 years old or older.<br/>

Additional terms and conditions may apply to purchases of specific Devices or other goods or services we make available to specific portions or features of the Services, and to subscriptions or licenses with entities with which you may be employed or affiliated. If there is a conflict between these Terms and the terms that are posted for or applicable to a specific goods or services or a specific portion of the Services, for any service offered on or through the Services, or set forth in a separate subscription or license agreement, the latter terms shall control with respect to your use of that portion of the Services, the specific goods or services, or the subscribed or licensed product.<br/>

Some of our Services are available on mobile devices or may utilize SMS/iMessage, which may cause you to incur SMS or data charges with your wireless provider.  Please be aware that we have no control over these charges, and if you do not wish to be charged, you should stop using the mobile or SMS/iMessage features (as applicable).<br/>

<br/><br/>2) Registration<br/><br/>

Certain Services require registration. You represent that all information you provide about yourself for registration purposes ("Registration Data") is true, accurate, current and complete information and you agree to keep such information up to date. You agree that we may use your Registration Data to provide Services to you and as otherwise set forth in these Terms and in our <a href="https://nkhome.com/nk/legal/privacy" target="_blank">Privacy Policy</a>. If we have grounds to suspect that such information is inaccurate or not current, we may suspend or terminate your account and access to Services.<br/>
<br/><br/>3) Security and System Reliability<br/><br/>

Keep your password secure. Maintaining the confidentiality of your passwords and access to your devices is solely your responsibility and you are responsible for all activities that occur under your account or from your devices. We do not guarantee absolute security of the Services, or of your account and your Submissions (as defined below), including personal information you provide, and we cannot guaranty that our security measures will prevent unauthorized persons from accessing the Services or its contents. Except as prohibited by applicable law, you waive all claims against us for any such unauthorized access. You agree to immediately notify us at [INSERT EMAIL] of any unauthorized use or your account or password, or any other breach of security. You acknowledge that your use of the Services is at your own risk.<br/>

You may register for or log-in to your account via a third-party service or network, such as Facebook or Google. If you do so, you hereby authorize us to pre-populate the registration and other relevant information fields of your account and/or to use such third-party credentials to log you into your account. If you connect your account to a third-party service or network, you agree to comply with any terms, conditions and/or policies applicable to such third-party service or network.<br/>

We use commercially reasonable efforts to make the Services available to you and to maintain saved information. However, you agree that we shall not be liable for any lost, altered, or corrupted data or information, or for any non-availability of the Services.<br/>
<br/><br/>4) Payments and Fees<br/><br/>

To pay any fee, you must designate and provide information about your preferred payment method (e.g., credit card, Paypal, iTunes or Google Play, etc.) ("Payment Method"). If you provide your payment information, you authorize us and our third-party service providers, payment card networks and payment processors, to receive, store and encrypt your payment information. You may switch to a different Payment Method or update your information by logging into your Services account. If you signed up through the Google Play or iTunes store, you would need to change your Payment Method through the corresponding third-party account. No refunds or credits will be provided by us other than as set forth in these Terms.  Interest will be charged on all unpaid delinquent amounts at the rate of 1.5% per month or the highest rate permitted by applicable law, whichever is lesser.  You agree to reimburse us for all collection agency fees, attorneys’ fees and other costs we may incur to collect delinquent amounts you owe to us. If your payment provider determines that there are fraudulent charges on your account resulting from use of your Payment Method at the Services, please contact us immediately at info@nkhome.com or by calling +1 610-447-1555.<br/><br/>

Certain features and functionalities of the Services may require payment of additional subscription fees ("Fees"). All Fees are subject to applicable taxes and are subject to change at any time upon reasonable notice. Fees will be billed in advance automatically at the start of the monthly or annual period, as applicable, and, if you so consent when subscribing, will auto-renew until your subscription is downgraded or terminated. You may cancel your subscription at any time as described below. Unless otherwise required by applicable law, Fees paid in advance are not refundable.<br/><br/>
<br/><br/>5) Paid Subscription Services<br/><br/>

Paid subscription services may start with a free trial. If you begin your subscription with a free trial, we will begin billing your Payment Method for monthly Fees at the end of the free trial period unless you cancel your subscription prior to the end of the free trial period. Your Payment Method will be authorized for up to approximately one month of paid Services as soon as you register for a free trial. In some instances, your available balance or credit limit may be reduced to reflect the authorization; however, no charges will be made against the Payment Method if you complete the cancellation process prior to the end of your free trial period.<br/><br/>

You may cancel your paid subscription at any time by logging into your Services account and visiting your "Account" page. If you signed up through the Google Play or iTunes store, you may cancel your subscription by downgrading through the corresponding store. The cancellation of a subscription will go into effect at the end of your current billing cycle. When your paid subscription ends, your account will revert to a free subscription. Terminating a paid subscription may result in the permanent deletion of content you have posted and/or your access to other content.<br/><br/>
<br/><br/>6) Deactivation<br/><br/>

You may deactivate your account at any time by logging into your Services account and selecting "Deactivate". At your request, we will delete your personally identifiable information, except as otherwise required by applicable laws. To do so, please contact us at privacy@nkhome.com or call us at +1 610-447-1555.<br/><br/>
<br/><br/>7) Content - Limitations on Use<br/><br/>

All content contained on or accessed from the Services, including text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, videos, images, applications, programs, computer code and other information (collectively, "Content"), including but not limited to the design, layout, "look and feel" and arrangement of such Content, is owned by NK or its licensors and is protected by copyright, trademark and other intellectual property and unfair competition laws.<br/><br/>

You may print or download Content from the Services for your own personal, non-commercial, informational or scholarly use, provided you keep intact all copyright and other proprietary notices.<br/><br/>

Except solely as set forth in the previous sentence, you may not copy, display, distribute, modify, publish, reproduce, store, transmit, post, translate or create other derivative works from, or sell, rent or license all or any part of the Content (other than your Submissions), products or services obtained from the Services in any medium to anyone, except as otherwise expressly permitted under these Terms, the relevant license or subscription agreement or as authorized in writing by us.<br/><br/>

You may not reverse engineer, disassemble, decompile or translated any software in the Content, or otherwise attempt to derive the source code of such software, except to the extent expressly permitted under applicable law, without our prior written permission. You may not engage in systematic retrieval of Content from the Services to create or compile, directly or indirectly, a collection, compilation, database or directory without our prior written permission.<br/><br/>

You may not use any robots, spiders, crawlers or other automated downloading programs, algorithms or devices, or any similar or equivalent manual process, or otherwise take any direct or indirection action to: (i) continuously and automatically search, scrape, extract, deep link or index any Content; (ii) harvest personal information (other than your personal information) from the Services; (iii) cause harm or disruption to the working of the Services or any other person's use of the Services; (iv) engage in fraudulent, deceptive or illegal conduct; or (v) impose an unreasonable load on our systems. If the Services contains robot exclusion files or robot exclusion headers, you agree to honor them and not use any device, software or routine to bypass them. You may not attempt to gain unauthorized access to any portion or feature of the Services, any other systems or networks connected to the Services or to any NK server, or any of the products or services provided on, accessed from or distributed through the Services. You may not probe, scan or test the vulnerability of the Services or any network connected to the Services or breach the security or authentication measures on the Services, or any network connected to the Services.<br/><br/>

You may not use the Services to publish or distribute any information (including software or other content) which is illegal, which violates or infringes upon the rights of any other person, which is defamatory, abusive, hateful, profane, pornographic, threatening or vulgar, which contains errors, viruses or other malicious code or harmful components, or which is otherwise actionable at law. We may at any time exercise editorial control over Content and/or delete any Content, without notice to you.<br/><br/>

Without our approval, you may not use the Services to publish or distribute any advertising, promotional material, or solicitation to other users of the Services to use any goods or services. Similarly, you may not use the Services to download and redistribute public information or shareware for personal gain or distribute multiple copies of public domain information or shareware.<br/><br/>

You acknowledge, consent and agree that NK may access, duplicate, distribute, display, modify, delete, preserve and/or disclose your Registration Data, Payment Method information and other Content as set forth in these Terms and/or in our Privacy Policy, or if required to do so by law or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce the Terms; (c) respond to claims that any Content violates the rights of third parties; (d) respond to your requests for customer Service; or (e) protect the rights, property or personal safety of NK, its members and the public.<br/><br/>

You agree to comply with all relevant local, state, national and international laws, statutes, ordinances and regulations that apply to your use of the Services and the Content. You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.<br/><br/>

You must comply with applicable third-party terms of agreement when using the Service, e.g., wireless data service provider agreements.<br/><br/>
<br/><br/>8) Submissions and Suggestions<br/><br/>

All Content, or any other content, application or other material that you or third parties provide to us or post, upload, input or submit on or through the Services, including our blog pages, message boards, chat rooms and forums, for review by the general public, registered users or by the members of any public or private community, whether publicly posted, privately transmitted, or submitted through a third party API (e.g. a photograph submitted via Instagram) are collectively referred to herein as "Submissions". You acknowledge and agree that  you are solely responsible, and we shall have no liability, for your Submissions’  content, accuracy or compliance with relevant laws or regulations. However, by posting, uploading, inputting, or otherwise providing or submitting your Submissions to us or to the Services ("Posting"), you grant us and our sub-licensees a royalty-free, perpetual, irrevocable, worldwide, non-exclusive right and license to display, publish, distribute, duplicate, modify, delete, and otherwise use your Submissions in any format in connection with the operation of our respective businesses.  Further, to the extent your Submissions include any suggestions, enhancement or modification requests, recommendations or other feedback relating to the operation of the Services (the "Suggestions"), we shall own, all right, title and interest in and to any such Suggestions, and you hereby assign and transfer to us all its right, title and interest (including all intellectual property rights) in and to the Suggestions.<br/><br/>

We are under no obligation to display or otherwise use any Submissions you may provide, nor are we obligated to attribute any Submissions to you, and we may remove any Submissions at any time in our sole discretion. By Posting Submissions, you also warrant and represent that you own or otherwise control all the rights to your Submissions including, without limitation, all the rights necessary for granting the permission specified above. We shall have the right, but not the obligation, to monitor Submissions to determine compliance with these Terms and any operating rules we establish and to satisfy any law, regulation or authorized government request. We shall have the right in our sole discretion to edit, refuse to post, or remove any Submissions. Under no circumstances will we be liable in any way for any information made available through the Services, including, but not limited to, any errors or omissions in any such information, or any loss or damage of any kind incurred as a result of the use of any information made available on the Services. You agree to bear all risks associated with the use of any information available in connection with the Services, including any reliance on the accuracy, completeness, or usefulness of such information.<br/><br/>

You agree that we are not responsible for, and do not endorse, third-party Content posted on the Services, including, without limitation, Content posted by users. If your Submission violates these Terms, you may bear legal responsibility for that Submission. As between you and us, any Submission will be non-confidential and non-proprietary and we will not be liable for any use or disclosure of Content that you post. You acknowledge and agree that your relationship with us is not a confidential, fiduciary, or other type of special relationship, and that your decision to submit any Submission does not place us in a position that is any different from the position held by members of the general public, including with regard to your Submission. Your Submission will not be subject to any obligation of confidence by us other than as set forth in the Privacy Policy.<br/><br/>

The Services may provide you with the option of making certain Submissions private or available only to select users. If, upon Posting of a Submission, you initially elect to mark such Submission as private or available for a limited group of members, we will use commercially reasonable efforts maintain the privacy of such Submission in accordance with your election. However, if you do not elect to mark your Submission as private or available for a limited group of members, or later change such designation to allow such Submission to be made publicly available, you are responsible for the public nature of the content. Please review our Privacy Policy for more information on how to manage your privacy settings.<br/><br/>
<br/><br/>9) Links<br/><br/>

The Services may contain links to third-party sites or resources. NK is not responsible for the availability of external sites or resources linked to the Services and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such sites or resources. Transactions that occur between you and any third party are strictly between you and the third party and are not the responsibility of NK. Because NK is not responsible for the availability or accuracy of these outside resources or their contents, you should review the terms and conditions and privacy policies of these linked sites, as their policies may differ from ours. Your interaction with, or participation in promotions of, third parties found on or through the Services, including payment and delivery of goods or services, and any other terms, are solely between you and such third party and you agree that we shall not have any liability in connection therewith.<br/><br/>

NK welcomes links to the Services. You may establish a hypertext link to any Service, provided that the link does not state or imply any sponsorship or endorsement of your site by NK. You may not use on your site any trademarks, service marks or copyrighted materials appearing on the Services, including but not limited to any logos, without the express written consent of the owner of the mark or right. You may not frame or otherwise incorporate into another site any of the Content or other materials on the Services without prior written consent of NK.<br/><br/>
<br/><br/>10) Communication with Other Users<br/><br/>

The Services may function as a venue to connect users in a virtual information place. NK is not directly involved in the actual communications between users of the Services and has no control over the truth, accuracy, quality, legality, or safety of any Postings by users of the Services. NK shall have no responsibility to confirm the identity of members or to confirm or verify the qualifications of users.  BY COMMUNICATING WITH OTHER USERS OF THE SERVICES, YOU CONSENT TO SUCH USERS COLLECTING INFORMATION FROM YOU.  YOU ACKNOWLEDGE AND AGREE THAT NK HAS NO CONTROL OVER THE OTHER USERS’ COLLECTION, USAGE, AND FURTHER DISCLOSURE OF ANY INFORMATION, INCLUDING PERSONAL INFORMATION (AS DEFINED IN OUR PRIVACY POLICY) THAT YOU DECIDE TO DISCLOSE.  ACCORDINGLY, YOU UNDERSTAND AND ASSUME THE RISKS OF REVEALING ANY PERSONAL INFORMATION TO OTHER USERS WITH WHOM YOU COMMUNICATE.<br/><br/>
<br/><br/>11) Disclaimer of Warranties; Limitations of Liability; Release of Claims<br/><br/>

NEITHER NK NOR ITS SUPPLIERS OR LICENSORS MAKES ANY WARRANTY WHATSOEVER, INCLUDING WITHOUT LIMITATION, THAT THE OPERATION OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE; THAT ANY DEFECTS WILL BE CORRECTED; THAT THE SERVICES, INCLUDING THE SERVER THAT MAKES IT AVAILABLE, IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR AS TO THE ACCURACY, COMPLETENESS, RELIABILITY, AVAILABILITY, SUITABILITY, QUALITY, NON-INFRINGEMENT, OPERATION OR RESULT OBTAINED FROM THE USE OF ANY CONTENT, PRODUCT OR SERVICE PROVIDED ON, ACCESSIBLE FROM OR DISTRIBUTED THROUGH THE SERVICES.<br/><br/>

THE NK SERVICES AND ALL CONTENT, PRODUCTS AND SERVICES INCLUDED IN OR ACCESSIBLE FROM THE NK SERVICES ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OR REPRESENTATIONS OF ANY KIND (EXPRESS, IMPLIED AND STATUTORY, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF TITLE AND NON-INFRINGEMENT AND THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE), ALL OF WHICH NK AND ITS AFFILIATES DISCLAIM TO THE FULLEST EXTENT PERMITTED BY LAW. YOUR USE OF THE NK SERVICES AND CONTENT IS AT YOUR SOLE RISK.<br/><br/>

TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, NO RESPONSIBILITY IS ASSUMED FOR ANY INJURY AND/OR DAMAGE TO PERSONS OR PROPERTY AS A MATTER OF PRODUCTS LIABILITY, NEGLIGENCE OR OTHERWISE, OR FROM ANY USE OR OPERATION OF ANY IDEAS, INSTRUCTIONS, METHODS, PRODUCTS OR PROCEDURES CONTAINED IN THE SERVICES.<br/><br/>

TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT SHALL NK, ITS AFFILIATES OR ITS SUPPLIERS OR LICENSORS BE LIABLE FOR ANY DAMAGES (INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, OR SIMILAR DAMAGES, PERSONAL INJURY (INCLUDING DEATH), LOSS OF PROFITS, CORRUPTION OR LOSS OF DATA, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES) ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THE NK SERVICES OR CONTENT, OR SHALL THE LIABILITY OF NK, ITS AFFILIATES OR ITS SUPPLIERS AND LICENSORS EXCEED A SUM EQUAL TO THE FEES PAID BY YOU HEREUNDER, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.<br/><br/>

IN ALL CASES RELATING TO PROVIDING YOU THE SERVICES, NK (AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS) AND ITS LICENSORS, SUPPLIERS, ADVERTISERS, AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE OR THAT IS DUE TO EVENTS OUTSIDE OF OUR REASONABLE CONTROL, SUCH AS WARS, CRIMINAL ACTIVITIES, STORMS, NATURAL DISASTERS, ACTS OF GOVERNMENT, SUPPLY INTERRUPTIONS, OR TELECOMMUNICATION OR INTERNET FAILURES.<br/><br/>

YOU AND YOUR HEIRS, SUCCESSORS, AND ASSIGNS HEREBY FOREVER IRREVOCABLY RELEASE, DISCHARGE, AND HOLD HARMLESS US, OUR AFFILIATES, AND OUR AND THEIR SUCCESSORS AND ASSIGNS, AND ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS  (COLLECTIVELY, “RELEASED PARTIES”) FROM, AND AGREE NOT TO SUE ANY RELEASED PARTY FOR, ANY LIABILITIES, CLAIMS, OBLIGATIONS, SUITS, ACTIONS, DEMANDS, EXPENSES, AND DAMAGES WHATSOEVER (COLLECTIVELY, “LIABILITIES”) THAT YOU MAY HAVE AGAINST ANY RELEASED PARTY WHETHER EXISTING NOW OR IN THE FUTURE, WHETHER KNOWN OR UNKNOWN, ARISING OUT OF OR IN CONNECTION WITH YOUR OR A THIRD PARTY’S CONDUCT RELATED TO USE OF THE SERVICES.  YOU UNDERSTAND AND ACKNOWLEDGE THAT THE FOREGOING SENTENCE RELEASES AND DISCHARGES ALL LIABILITIES, WHETHER OR NOT THEY ARE CURRENTLY KNOWN TO YOU, AND YOU WAIVE YOUR RIGHTS UNDER CALIFORNIA CIVIL CODE SECTION 1542.  IF CALIFORNIA LAW APPLIES TO YOU, IN ENTERING INTO THIS RELEASE, YOU ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND, AND HEREBY EXPRESSLY WAIVE THE BENEFITS OF, SECTION 1542 OF THE CIVIL CODE OF CALIFORNIA, (AND ANY SIMILAR LAW OF ANY STATE, COUNTRY OR TERRITORY), WHICH PROVIDES AS FOLLOWS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."  BY AGREEING TO THESE TERMS AND THIS WAIVER, YOU ASSUME ALL RISK ARISING FROM YET UNKNOWN CLAIMS.<br/><br/>
<br/><br/>12) Indemnity<br/><br/>

You agree to indemnify, defend and hold NK and its subsidiaries, affiliates, officers, agents, representatives, employees, partners and licensors (together, "NK Group") harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of Content you submit, post, transmit or otherwise seek to make available through the Services, your use of the Services, your activities which generate the Content you post or seek to post on the Services (including, but not limited to, athletic activities in connection with any events which NK sponsors, organizes, participates in, or whose Services are used in connection with), your connection to the Services, your violation of the Terms, or your violation of any rights of another person or entity. You further agree to indemnify, defend and hold NK Group harmless from any claim or demand, including reasonable attorneys' fees, arising out of your use of the Services except to the extent caused by the gross negligence or willful misconduct of NK.<br/><br/>
<br/><br/>13) Proprietary Rights<br/><br/>

The term Nielsen-Kellerman, the NK logo and other NK logos and product and Service names are the exclusive trademarks of NK and are owned by us or our affiliates and you may not use or display such trademarks in any manner without our prior written permission. You are expressly forbidden from using any trademark, logo or service mark belonging to NK, registered or unregistered, in any URL and/or social media group or page name without written license from NK.<br/><br/>

The Services, any software used in connection with the Services, and any data and/or content based on the Services contain proprietary and confidential information that is protected intellectual property and other laws. You acknowledge and agree that the Services are so protected and agree not to modify, rent, lease, sell, distribute or create derivative works based on the Services, the software or Content (other than Content submitted by you).<br/><br/>

NK reserves all rights not expressly granted hereunder.<br/><br/>
<br/><br/>14) App License and Warranty<br/><br/>

You agree that in addition to these Terms, your use of our App is subject to the usage rules set forth in Apple’s App Store terms of service, if you download our app from the App Store, or in Google’s Play terms of service, if you download the app from Google Play, or any other third party platform, developer or distributor end-user license agreement and/or terms and conditions by which you agree to be bound when you download our App or otherwise access the Services.<br/><br/>

 
	Acknowledgment<br/><br/>
	You acknowledge that these Terms are an agreement between you and NK only, and not an agreement with the App reseller or distributor, i.e., Apple or Google ("Distributor"), and NK, not Distributor, is solely responsible for the App and the content thereof.<br/><br/>
	License<br/><br/>
	NK grants to you a nontransferable non-exclusive license to use the App on any devices that you own or control and as permitted by the usage rules, if any, set forth in the Distributor's terms of service ("Usage Rules"). Except as provided in the Usage Rules, you may not distribute or make the App available over a network where it could be used by multiple devices at the same time. You may not transfer, redistribute or sublicense the App and, if you sell your device to a third party, you must remove the App from the device before doing so. You may not copy (except as permitted by this license and the Usage Rules), reverse-engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of the Licensed App, any updates, or any part thereof (except as and only to the extent that any foregoing restriction is prohibited by applicable law or to the extent as may be permitted by the licensing terms governing use of any open-sourced components included with the Licensed App).<br/><br/>
	App Warranty<br/><br/>
	Any warranties not disclaimed herein are the sole responsibility of NK and not the Distributor. In the event of any failure of any App to conform to any applicable warranty, you may notify Distributor to obtain a refund of the purchase price for the App. To the maximum extent permitted by applicable law, the Distributor will have no other warranty obligation whatsoever with respect to the App, and you waive the right to assert against the Distributor any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty.<br/><br/>
	You acknowledge that NK and not Distributor is responsible for addressing any claims relating to the App, including, but not limited to: (i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection, privacy, or similar legislation, including in connection with the App.<br/><br/>
	You represent and warrant that (i) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist-supporting” country; and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties.  Distributor, and Distributor’s subsidiaries, are third party beneficiaries of this agreement, and upon your acceptance of the terms and conditions of the agreement, Distributor will have the right (and will be deemed to have accepted the right) to enforce the agreement against you as a third-party beneficiary thereof.<br/><br/>
<br/><br/>15) Infringement Claims<br/><br/>

The Digital Millennium Copyright Act (the "DMCA") provides recourse for copyright owners who believe that material appearing on the internet infringes their rights under U.S. copyright law. If you believe in good faith that materials hosted by NK infringe your copyright or trademark rights, you (or your agent) may send us a notice requesting that the material be removed, or access to it blocked. The notice must include the following information: (a) identification of the work or material being infringed; (b) identification of the material that is claimed to be infringing, including its location, with sufficient detail so that we are capable of finding it and verifying its existence; (c) contact information for the notifying party (the "Notifying Party"), including name, address, telephone number, and email address; (d) a statement that the Notifying Party has a good faith belief that the material is not authorized by the copyright owner, its agent or law; (e) a statement made under penalty of perjury that the information provided in the notice is accurate and that the Notifying Party is authorized to make the complaint on behalf of the copyright owner. Your notice must be signed (physically or electronically) and must be addressed as follows: Nielsen-Kellerman, Attn: Legal Department, 21 Creek Circle, Boothwyn, PA 19061 USA or legal@nkhome.com.<br/><br/>

If you fail to comply with all of the requirements of this section, your notice may not be valid. The information provided in a notice of copyright infringement may be forwarded to the person who posted the allegedly infringing content. In the U.S., under Section 512(f) of the DMCA, any person who knowingly materially misrepresents that material or activity is infringing may be subject to liability. Please see www.copyright.gov or search "DMCA takedown notice" for more information about how to prepare or respond to a DMCA notice.<br/><br/>

You acknowledge that, in the event of any third-party claim that the App or your possession and use of the App infringes that third party's intellectual property rights, NK, not Distributor, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.<br/><br/>
<br/><br/>16) Jurisdictional Notices<br/><br/>

If you are a California resident, under California Civil Code Section 1789.3, California Services users are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., Suite N 112, Sacramento, California 95834, or by telephone at (800) 952-5210.<br/><br/>

If you are a UK resident, For the purposes of English law, nothing in these Terms limits or excludes our responsibility for fraudulent representations made by us or for death or personal injury caused by our negligence or willful misconduct.<br/><br/>
<br/><br/>17) Binding Arbitration<br/><br/>

Without limiting your waiver and release in Section 11, you agree to the following:<br/><br/>
 
	Purpose. Any and all Disputes (as defined below) involving you and NK will be resolved through individual arbitration. In arbitration, there is no judge or jury and there is less discovery and appellate review than in court. This Section 17 (the "Arbitration Provision") shall be broadly interpreted. Notwithstanding anything to the contrary in these Terms, this Section 17 does not apply to an action by either party to enjoin the infringement or misuse of its intellectual property rights, including copyright, trademark, patent or trade secret rights.
	Definitions. The term "Dispute" means any claim or controversy related to the Services or the Software, including but not limited to any and all: (1) claims for relief and theories of liability, whether based in contract, tort, fraud, negligence, statute, regulation, ordinance, or otherwise; (2) claims that arose before these Terms or any prior agreement; (3) claims that arise after the expiration or termination of these Terms; and (4) claims that are currently the subject of purported class action litigation in which you are not a member of a certified class.  As used in this Arbitration Provision, "NK" means Nielsen-Kellerman Co. and/or subsidiary or affiliated company Ambient, LLC or RainWise, Inc., and any of its or their predecessors, successors, assigns, parents, subsidiaries and affiliated companies and each of their respective officers, directors, employees and agents, and “you” means you and any users or beneficiaries of your access to the Services.
	Initiation of Arbitration Proceeding/Selection of Arbitrator. The party initiating the arbitration proceeding may open a case with JAMS, formerly Judicial Arbitration and Mediation Services, Inc., (“JAMS”) by visiting its website (www.jamsadr.com) or calling its toll-free number (1-800-352-5267). You may deliver any required or desired notice to NK by mail to Nielsen-Kellerman Co. at 21 Creek Circle, Boothwyn, PA 19061 USA.
	Right to Sue in Small Claims Court. Notwithstanding anything in this Arbitration Provision to the contrary, either you or NK may bring an individual action in a small claims court in the area where you access the Services if the claim is not aggregated with the claim of any other person and if the amount in controversy is properly within the jurisdiction of the small claims court.
	Arbitration Procedures. This Arbitration Provision shall be governed by the Federal Arbitration Act. Arbitrations shall be administered by JAMS pursuant to its Streamlined Arbitration Rules and Procedures (the “JAMS Rules”) as modified by the version of this Arbitration Provision that is in effect when you notify NK about your Dispute. You can obtain the JAMS Rules from the JAMS by visiting its website (www.jamsadr.com) or calling its toll-free number (1-800-352-5267). If there is a conflict between this Arbitration Provision and the rest of these Terms, this Arbitration Provision shall govern. If there is a conflict between this Arbitration Provision and the JAMS rules, this Arbitration Provision shall govern. If JAMS will not administer a proceeding under this Arbitration Provision as written, the parties shall agree on a substitute arbitration organization. If the parties cannot agree, the parties shall mutually petition a court of appropriate jurisdiction to appoint an arbitration organization that will administer a proceeding under this Arbitration Provision as written applying the JAMS Rules. A single arbitrator will resolve the Dispute. Unless you and NK agree otherwise in writing, any arbitration hearing will take place in Philadelphia, PA.   The arbitrator will honor claims of privilege recognized by law and will take reasonable steps to protect customer account information and other confidential or proprietary information. The arbitrator shall issue a reasoned written decision that explains the arbitrator’s essential findings and conclusions. The arbitrator’s award may be entered in any court having jurisdiction over the parties only if necessary for purposes of enforcing the arbitrator’s award. An arbitrator’s award that has been fully satisfied shall not be entered in any court.
	Waiver of Class Actions and Collective Relief. THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO BE ARBITRATED OR LITIGATED ON A CLASS ACTION, JOINT OR CONSOLIDATED BASIS OR ON BASES INVOLVING CLAIMS BROUGHT IN A PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL PUBLIC (SUCH AS A PRIVATE ATTORNEY GENERAL), OTHER SUBSCRIBERS, OR OTHER PERSONS. THE ARBITRATOR MAY AWARD RELIEF ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF WARRANTED BY THAT INDIVIDUAL PARTY’S CLAIM. THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING.
	Arbitration Fees and Costs. If your claim seeks more than $75,000 in the aggregate, the payment of the JAMS’s fees and costs will be governed by the JAMS Rules. If your claims seek less than $75,000 in the aggregate, the payment of the JAMS’s fees and costs will be NK’s responsibility. However, if the arbitrator finds that your Dispute was frivolous or brought for an improper purpose (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)), the payment of the JAMS’s fees and costs shall be governed by the JAMS Rules and you shall reimburse NK for all fees and costs that were your obligation to pay under the JAMS Rules. You may hire an attorney to represent you in arbitration. You are responsible for your attorneys’ fees and additional costs and may only recover your attorneys’ fees and costs in the arbitration to the extent that you could in court if the arbitration is decided in your favor. Notwithstanding anything in this Arbitration Provision to the contrary, NK will pay all fees and costs that it is required by law to pay.
	Severability and Waiver of Jury Trial. If any part of subsection (f) of this Arbitration Provision is found to be illegal or unenforceable, the entire Arbitration provision will be unenforceable and the Dispute will be decided by a court. WHETHER IN COURT OR IN ARBITRATION, YOU AND NK AGREE TO WAIVE THE RIGHT TO A TRIAL BY JURY TO THE FULLEST EXTENT ALLOWED BY LAW. If any other clause in this Arbitration Provision is found to be illegal or unenforceable, that clause will be severed from this Arbitration Provision and the remainder of this Arbitration Provision will be given full force and effect.
	Continuation. This Arbitration Provision will survive the termination or expiration of these Terms.
<br/><br/>18) Choice of Law and Forum<br/><br/>

All matters relating to your access to or use of the Services, including all disputes, shall be governed by and construed in accordance with the laws of the Commonwealth of Pennsylvania, USA, without regard to its conflicts of law principles. THE UNITED NATIONS CONVENTIONS ON CONTRACTS FOR THE INTERNATIONAL SALE OF GOODS DO NOT APPLY. Subject to the Section entitled "Dispute Resolution", the exclusive jurisdiction and venue with respect to any action or suit arising out of or pertaining to the subject matter hereof shall be the courts of competent jurisdiction located in the Commonwealth of Pennsylvania, USA. Any claim arising out of or in connection with your use of or inability to use the Services or its Content, products or services must be brought within one (1) year after the event, or such claim is barred.<br/><br/>
<br/><br/>19) International Use<br/><br/>

If you are not a United States resident and you are accessing the Services from outside the United States, you agree to transfer certain information outside your home country to us, and that you will follow all the laws that apply to you. Our servers and operations are located primarily in the United States, and our policies and procedures are based primarily on United States law. Because of this, the following provisions apply specifically to users located outside of the United States: (i) you consent to the transfer, storage, and processing of your information, including but not limited to your content and personal data, to and in the United States and/or other countries; and (ii) you agree to comply with all local laws, rules, and regulations including, without limitation, all laws, rules, and regulations in effect in the country in which you reside and the country from which you access the Services. The Services are not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation, or which that would subject NK or its affiliates to any registration requirement within such jurisdiction or country. You may not use or export the Content in violation of U.S. export laws and regulations. This agreement was written in English. To the extent a translated version of the Terms conflict with the English version, the English version controls.<br/><br/>
<br/><br/>20) Termination<br/><br/>

You agree that NK may, under certain serious circumstances and without prior notice, immediately terminate your account and/or access to the Services. Cause for such termination shall include, but not be limited to, (a) breaches or violations of the Terms or other incorporated agreements, policies or guidelines, (b) requests by law enforcement or other government agencies, (c) a request by you (self-initiated account deletions), (d) discontinuance or material modification to the Services (or any portion thereof), (e) unexpected technical or security issues or problems, (f) extended periods of inactivity, and/or (g) nonpayment of any fees owed by you in connection with the Services. Termination of your account may include (x) removal of access to all offerings within the Services, (y) deletion of your information, files and Content associated with your account, and (z) barring of further use of the Services. Further, you agree that all terminations for cause shall be made in NK's sole discretion and that NK shall not be liable to you or any third party for any termination of your account or access to the Services. All rights and remedies of NK shall survive any termination of these Terms.<br/><br/>
<br/><br/>21) General<br/><br/>

You agree that no joint venture, partnership, employment or agency relationship exists between you and NK as a result of the Terms or your use of the Services. The Terms constitute the entire agreement between you and NK with respect to your use of the Services. The failure of NK to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect. NK's notice to you via email, notices, posts, or links on the Services shall constitute acceptable notice to you under the Terms. We are not responsible for your failure to receive notice if email is quarantined by your email security system (e.g., "junk" or "spam" folder) or if you fail to update your email address. Notice will be considered received forty-eight hours after it is sent if transmitted via email or regular mail. In the event that notice is provided via links displayed in connection with the Services, then it will be considered received twenty-four hours after it is first displayed. Section titles and headings in the Terms are for convenience only and have no legal or contractual effect. Any rights not expressly granted herein are reserved. You acknowledge that Distributor and its affiliates and subsidiaries are third-party beneficiaries of these Terms, and that, upon your acceptance of these Terms, Distributor will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as a third-party beneficiary.<br/><br/>
<br/><br/>22) Updates and Product-Specific Terms<br/><br/>
 
	Updates to these Terms<br/><br/>
	We reserve the right to modify these Terms by (i) posting revised Terms on and/or through the Services, and/or (ii) providing notice to you of material changes to the Terms, generally via email where practicable, and otherwise through the Services (such as through a notification on our home page or in our Apps). Modifications will not apply retroactively unless required by law. We may sometimes ask you to review and to explicitly agree to or reject a revised version of the Terms. In such cases, modifications will be effective at the time of your agreement to the modified version of the Terms. If you do not agree at that time, you are not permitted to use the Services. In cases where we do not ask for your explicit agreement to a modified version of the Terms, the modified version of the Terms will become effective as of the date specified in the Terms. Your use of the Services following that date constitutes your acceptance of the terms and conditions of the Terms as modified. If you do not agree to the modifications, you are not permitted to use, and should discontinue your use of, the Services.<br/><br/>
	Product-Specific Terms<br/><br/>
	We may also require you to agree to additional terms, rules, policies, guidelines, or other conditions (collectively, "Product-Specific Terms") that are specific to certain Services. In such cases, you may be required to expressly consent to Product-Specific Terms. For instance, you might need to check a box or click on a button marked "I agree." If any of the Product-Specific Terms are different than the Terms, the Product-Specific Terms will supplement, amend, or supersede the Terms, but only with respect to the subject matter of the Product-Specific Terms.<br/><br/>

<br/><br/>23) Contact Us<br/><br/>
If you have any feedback, questions or comments about the Services, or need to reach us for any reasons, please contact us by email at info@nkhome.com, by phone at +1 (610) 447-1555, or by mail at: Nielsen-Kellerman, 21 Creek Circle, Boothwyn, PA 19061 USA. You may also start a chat session at any Site or contact us via Facebook.<br/><br/>
        </pre>
      </div>
    );
  }
}

TermsOfUse.displayName = 'TermsOfUse'
