import React from 'react';
// import PropTypes from 'prop-types';
import DisplayCard from './DisplayCard'
import DisplaySubscription from './DisplaySubscription';
import { path } from 'ramda'
import { AllPayments, hasCellularDevice } from '.'


export default function ManageSubscriptions({ user }) {

  const userId = path(['info', '_id'], user)
  const subs = path(['info', 'payments', 'subscriptions'], user)
  const mode = "file"

  return (
    <div className="payment-manage-subscriptions block">
      {/* <h2>Manage Subscriptions</h2>
      <br /><br /> */}
      <h3>Credit Card on File</h3>
      <p>This card will be used if you choose the auto-renew option for any of your subscriptions.</p>
      <DisplayCard user={user} />
      <AllPayments userId={userId} mode="file" />

      <h3 id='subscriptions'>Your Subscriptions</h3>
      <p>Cancel your subscription at any time by turning off auto-renew.  You can turn auto-renew back on any time before the subscritpion expires.</p>
      <div className="subscription-list">
        {/* {(hasCellularDevice(device) || (subs && (subs.length > 0))) ? <DisplaySubscription /> : "You currently have no subscriptions."} */}
        <DisplaySubscription />
      </div>

    </div>
  );
};

ManageSubscriptions.propTypes = {};
ManageSubscriptions.defaultProps = {};

ManageSubscriptions.displayName = 'ManageSubscriptions'
