import { createBrowserHistory, createHashHistory } from 'history'
import { isCordova, checkOldLinks } from './ambient'

// A singleton history object for easy API navigation
const history = isCordova() ? createHashHistory() : createBrowserHistory()

let lastPathName = false
history.listen(location => {
  checkOldLinks(location)
  if (typeof ga !== 'undefined' && lastPathName !== location.pathname) {
    ga('send', 'pageview', location.pathname)
    lastPathName = location.pathname
  }
})
export default history
