import { WxwDefault } from '../device';
import { AdminDataReports } from '../device';
import {
  DefaultPage,
  Signin,
  Confirm,
  Settings,
  Account,
  Admin,
  PasswordReset,
  AdminAlexa,
  AdminSocial,
  HomePage,
  WhereTo,
  Dashboard,
  KmSupport,
  BarometerCalibration,
} from './index';

export default {
  path: '',
  childRoutes: [
    { path: 'add-device', component: DefaultPage },
    { path: 'welcome', component: DefaultPage },
    { path: 'register', component: DefaultPage },
    { path: 'dashboard', component: Dashboard },
    { path: 'dashboard/:key', component: Dashboard },
    { path: 'dashboard/:key/:tab', component: Dashboard },
    { path: 'dashboard/:key/:tab/:id', component: Dashboard },
    { path: 'wxw', component: WxwDefault },
    { path: 'signin', component: Signin },
    { path: 'confirm', component: Confirm },
    { path: 'settings', component: Settings },
    { path: 'account', component: Account },
    { path: 'account/:tab', component: Account },
    { path: 'admin', component: Admin },
    { path: 'password-reset', component: PasswordReset },
    { path: 'admin/social', component: AdminSocial },
    { path: 'admin/alexa', component: AdminAlexa },
    { path: 'admin/data', component: AdminDataReports },
    { path: 'home-page', component: HomePage, isIndex: true },
    { path: 'index.html', component: HomePage },
    { path: 'where-to', component: WhereTo },
    { path: 'baro', component: BarometerCalibration },
    { path: 'support', component: KmSupport },
  ],
};
