import React, { useEffect, useRef, useState } from 'react'
// import PropTypes from 'prop-types';
import { DisplayCard, AllPayments, userHasCardOnFile, getAwnPlusAppPrice, freeTrialUsed } from './'
import { Loader } from '../../components'
import { path } from 'ramda'
import { useRefreshUser } from '../user/redux/refreshUser'
import { appIsGteVersion, isAndroidApp, isApp, isIosApp } from '../../common/ambient'
import { billingSubscribe } from './billingAccess'
import { useAddUserAction } from '../social/redux/addUserAction'
import bindDeviceActions from '../device/redux/bindDeviceActions'

function SubscribeProcess ({ user, subscription, children, device, actions }) {
  const [hasPaymentMethod, setHasPaymentMethod] = useState(userHasCardOnFile(user))
  const [loading, setLoading] = useState(false)
  const intRef = useRef(null)
  const { refreshUser } = useRefreshUser()
  const { addUserAction } = useAddUserAction()
  useEffect(() => {
    return () => {
      clearInterval(intRef.current)
    }
  }, [])
  useEffect(() => {
    if (device.appPurchaseFlowCanceled && loading) {
      setLoading(false)
      actions.setThing('appPurchaseFlowCanceled', false)
    }
  }, [device, loading, setLoading, actions])
  const annual = /yearly/.test(subscription)
  const appSubscriptionId = annual ? 281025 : 281024
  let price = annual ? '$49.99' : '$4.99'
  let btnDisabled = !hasPaymentMethod || loading
  if (isApp()) {
    btnDisabled = false
    if (getAwnPlusAppPrice(device, annual)) {
      price = getAwnPlusAppPrice(device, annual)
    }
  }
  let btnText = `Buy Plan - ${price}`
  if (!freeTrialUsed(user, 'awn-plus-free-trial')) {
    btnText = <span>Free month trial <span className='then'>then {price}</span></span>
  }

  // btnBuyHandler ---------------------------------
  async function btnBuyHandler () {
    setLoading(true)
    actions.setThing('appPurchaseFlowCanceled', false)
    if (typeof window.saveAppleSubscriptionPricingInfo === 'undefined') {
      window.saveAppleSubscriptionPricingInfo = (data) => {
        addUserAction({
          data,
          // we need "to" for the index
          to: {
            type: 'user',
            _id: user.info._id
          },
          type: 'iap-subscription'
        })
      }
    }
    const userId = path(['info', '_id'], user)
    // iOS native flow
    if (isIosApp()) {
      if (!appIsGteVersion('4.5.0')) {
        setLoading(true)
        actions.setThing('appPurchaseFlowCanceled', true)
        window.alert('Please update your app to the latest version to subscribe.')
        window.open('https://apps.apple.com/us/app/ambient-weather-network/id1426025887', '_blank')
        return
      }
      window.webkit.messageHandlers.buySubscriptionWebNotification.postMessage({ userId, subscriptionId: appSubscriptionId })
    // android native flow
    } else if (isAndroidApp()) {
      if (!appIsGteVersion('4.5.0')) {
        setLoading(true)
        actions.setThing('appPurchaseFlowCanceled', true)
        window.alert('Please update your app to the latest version to subscribe.')
        window.open('https://play.google.com/store/apps/details?id=net.ambientweather.dashboard', '_blank')
        return
      }
      window.ANDROID && window.ANDROID.buySubscriptionWebNotification(appSubscriptionId, userId)
    // stripe flow (not ios or android native app)
    } else {
      const billingSubOpts = {}
      let productName = subscription
      // changing product name requires a PUT
      if (user.plus && user.plus.productName !== productName) {
        billingSubOpts.body = { new_product_name: productName }
        productName = user.plus.productName
        billingSubOpts.method = 'PUT'
      }
      await billingSubscribe(path(['info', '_id'], user), null, productName, billingSubOpts)
      intRef.current = setInterval(() => {
        refreshUser()
      }, 1500)
    }
  }
  // btnBuyHandler ---------------------------------

  let show = ''
  if (path(['info', '_id'], user)) {
    show = (
      <>
        {!isApp() && (
          <>
            <DisplayCard />
            <AllPayments
              userId={path(['info', '_id'], user)}
              mode='file'
              onSuccess={setHasPaymentMethod}
            />
          </>
        )}
        <div className='buyBtn'>
          <button
            className='btn btn-primary buy-plan'
            disabled={btnDisabled}
            onClick={btnBuyHandler}
          >
            {btnText}
          </button>
          {loading ? <><br /><p>Please wait</p><Loader /></> : ''}
          {children}
        </div>
      </>
    )
  }
  return (
    <div className='subscribe-process'>
      {show}
    </div>
  )
}

SubscribeProcess.propTypes = {}
SubscribeProcess.defaultProps = {}

SubscribeProcess.displayName = 'SubscribeProcess'

export default bindDeviceActions(SubscribeProcess)
