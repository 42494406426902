import { isBillingDev, mergeDeep } from '../../common/ambient'

const fetch = require('isomorphic-fetch')

/// ////////////////////////////////////////////////////////////////////////////
// SET THE BILLING SERVER HERE
// Note, the AWN backend MUST match.  Rainmaker requires lightning.  Test server requires tornado (search for it)
// Note, the Stripe publishable key MUST match.  Use pk_test_ or pk_live_.  Search for PUBLISHABLE_KEY.
// const billingServer = "https://rainmaker.ambientweather.net"  // Production server
const billingServer = isBillingDev() ? 'https://ambient-weather-billing-test-z49ii.ondigitalocean.app' : 'https://rainmaker.ambientweather.net'
/// ////////////////////////////////////////////////////////////////////////////

export async function fetchFromBilling (path, fetchObj) {
  const url = billingServer + path
  const jwt = localStorage.getItem('feathers-jwt')
  try {
    const response = await fetch(url, mergeDeep({
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    }, fetchObj))
    return response.json()
  } catch (error) {
    _handleError(error)
  }
}

async function _getFromBilling (path) {
  const url = billingServer + path
  const jwt = localStorage.getItem('feathers-jwt')
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  })
  return response
}

function _handleError (error) {
  // console.log('###________________________________###')
  // console.log(error)
  // console.log('###________________________________###')
}

/// ////////////////////////////////////////////////////////////////////////////
//
export async function billingFetchSecret (userId, setSecretTo) {
  try {
    const url = billingServer + '/users/' + userId + '/card-setup'
    const jwt = localStorage.getItem('feathers-jwt')
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
    const clientSecret = await response.json()
    // console.log('    HTTP return: ' + JSON.stringify(response))
    // console.log('    JSON return: ' + JSON.stringify(clientSecret))
    // console.log("    Client secret:" + clientSecret.clientSecret)
    setSecretTo(clientSecret.clientSecret)
  } catch (error) {
    _handleError(error)
  }
};

/// ////////////////////////////////////////////////////////////////////////////
// GET /users/:userId/cards
export async function billingGetUserCards (userId, setUserCards) {
  try {
    const path = '/users/' + userId + '/cards'
    const response = await _getFromBilling(path)
    const userCards = await response.json()
    setUserCards(userCards)
    return (response.status >= 200 && response.status <= 299)
  } catch (error) {
    _handleError(error)
    return false
  }
};


/// ////////////////////////////////////////////////////////////////////////////
//  GET /subscriptions?userId=someUserId
//  GET /subscriptions?userId=someUserId&productId=someProductId (optional)
export async function billingGetUserSubs (userId, setUserSubs) {
  try {
    const url = '/subscriptions?userId=' + userId
    const response = await _getFromBilling(url)
    const userSubs = await response.json()
    setUserSubs(userSubs)
    return (response.status >= 200 && response.status <= 299)
  } catch (error) {
    _handleError(error)
    return false
  }
};

/// ////////////////////////////////////////////////////////////////////////////
// PUT /subscriptions?userId=${userId}&macAddress=${macAddress}&productName=${productName}

export async function billingSetAutorenew (userId, macAddress, productName, renew) {
  // console.log("%%%%% Entering billingSetAutorenew")
  try {
    const url = billingServer + '/subscriptions?userId=' + userId + '&macAddress=' + macAddress + '&productName=' + productName
    const jwt = localStorage.getItem('feathers-jwt')
    const cancel_at_period_end = !renew

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ cancel_at_period_end })
    })
    const autorenewResponse = await response.json()
    // console.log('    autorenewResponse return: ' + JSON.stringify(autorenewResponse))
    return (response.status >= 200 && response.status <= 299)
  } catch (error) {
    _handleError(error)
    return false
  }
};

/// ////////////////////////////////////////////////////////////////////////////
// POST /subscriptions?userId=${userId}&macAddress=${macAddress}&productName=${productName}
export async function billingSubscribe (userId, macAddress, productName, opts = {}) {
  const jwt = localStorage.getItem('feathers-jwt')
  let body
  const headers = {
    Authorization: `Bearer ${jwt}`
  }
  if (opts.body) {
    body = JSON.stringify(opts.body)
    headers['Content-Type'] = 'application/json'
  }
  try {
    const url = billingServer + '/subscriptions?userId=' + userId + '&macAddress=' + macAddress + '&productName=' + productName

    const response = await fetch(url, {
      method: opts.method || 'POST',
      headers,
      body
    })
    const subscriptionResponse = await response.json()
    // console.log('    subscriptionResponse return: ' + JSON.stringify(subscriptionResponse))
    if (response.status >= 200 && response.status <= 299) {
      return true
    } else {
      return false
    }
  } catch (error) {
    _handleError(error)
    return false
  }
};

/// ////////////////////////////////////////////////////////////////////////////
// POST http://servername/users/:userId/cards/:paymentId/default (empty body)
//
export async function billingMakeCardDefault (userId, paymentId) {
  try {
    const url = billingServer + '/users/' + userId + '/cards/' + paymentId + '/default'
    const jwt = localStorage.getItem('feathers-jwt')

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })

    const makeCardDefaultResponse = await response.json()

    if (response.status >= 200 && response.status <= 299) {
      return true
    } else {
      return false
    }
  } catch (error) {
    _handleError(error)
    return false
  }
};

/// ////////////////////////////////////////////////////////////////////////////
// DELETE /users/:userId/cards/:cardId
export async function billingDeleteCard (userId, paymentId) {
  try {
    const url = billingServer + '/users/' + userId + '/cards/' + paymentId
    const jwt = localStorage.getItem('feathers-jwt')

    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })

    const deleteCardResponse = await response.json()

    if (response.status >= 200 && response.status <= 299) {
      return true
    } else {
      return false
    }
  } catch (error) {
    _handleError(error)
    return false
  }
};
