export const DEVICE_TEST_ACTION = 'DEVICE_TEST_ACTION';
export const UPDATE_CURRENT = 'UPDATE_CURRENT';
export const SAVE_CURRENT_BEGIN = 'SAVE_CURRENT_BEGIN';
export const SAVE_CURRENT_SUCCESS = 'SAVE_CURRENT_SUCCESS';
export const SAVE_CURRENT_FAILURE = 'SAVE_CURRENT_FAILURE';
export const SAVE_CURRENT_DISMISS_ERROR = 'SAVE_CURRENT_DISMISS_ERROR';
export const FETCH_DEVICES_BEGIN = 'FETCH_DEVICES_BEGIN';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_FAILURE = 'FETCH_DEVICES_FAILURE';
export const FETCH_DEVICES_DISMISS_ERROR = 'FETCH_DEVICES_DISMISS_ERROR';
export const REMOVE_DEVICE_BEGIN = 'REMOVE_DEVICE_BEGIN';
export const REMOVE_DEVICE_SUCCESS = 'REMOVE_DEVICE_SUCCESS';
export const REMOVE_DEVICE_FAILURE = 'REMOVE_DEVICE_FAILURE';
export const REMOVE_DEVICE_DISMISS_ERROR = 'REMOVE_DEVICE_DISMISS_ERROR';
export const CHECK_MAC_ADDRESS_BEGIN = 'CHECK_MAC_ADDRESS_BEGIN';
export const CHECK_MAC_ADDRESS_SUCCESS = 'CHECK_MAC_ADDRESS_SUCCESS';
export const CHECK_MAC_ADDRESS_FAILURE = 'CHECK_MAC_ADDRESS_FAILURE';
export const CHECK_MAC_ADDRESS_DISMISS_ERROR = 'CHECK_MAC_ADDRESS_DISMISS_ERROR';
export const FETCH_DEVICE_DATA_BEGIN = 'FETCH_DEVICE_DATA_BEGIN';
export const FETCH_DEVICE_DATA_SUCCESS = 'FETCH_DEVICE_DATA_SUCCESS';
export const FETCH_DEVICE_DATA_FAILURE = 'FETCH_DEVICE_DATA_FAILURE';
export const FETCH_DEVICE_DATA_DISMISS_ERROR = 'FETCH_DEVICE_DATA_DISMISS_ERROR';
export const FETCH_DEVICE_DATA_CLEAR_HIRES = 'FETCH_DEVICE_DATA_CLEAR_HIRES';
export const FETCH_DEVICE_DATA_CLEAR_DATA_KEY = 'FETCH_DEVICE_DATA_CLEAR_DATA_KEY';
export const REMOVE_DEVICE_DATA_BEGIN = 'REMOVE_DEVICE_DATA_BEGIN';
export const REMOVE_DEVICE_DATA_SUCCESS = 'REMOVE_DEVICE_DATA_SUCCESS';
export const REMOVE_DEVICE_DATA_FAILURE = 'REMOVE_DEVICE_DATA_FAILURE';
export const REMOVE_DEVICE_DATA_DISMISS_ERROR = 'REMOVE_DEVICE_DATA_DISMISS_ERROR';
export const PATCH_BEGIN = 'PATCH_BEGIN';
export const PATCH_SUCCESS = 'PATCH_SUCCESS';
export const PATCH_FAILURE = 'PATCH_FAILURE';
export const PATCH_DISMISS_ERROR = 'PATCH_DISMISS_ERROR';
export const SET_DEVICE_I = 'SET_DEVICE_I';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const FETCH_ALL_DEVICES_BEGIN = 'FETCH_ALL_DEVICES_BEGIN';
export const FETCH_ALL_DEVICES_SUCCESS = 'FETCH_ALL_DEVICES_SUCCESS';
export const FETCH_ALL_DEVICES_FAILURE = 'FETCH_ALL_DEVICES_FAILURE';
export const FETCH_ALL_DEVICES_DISMISS_ERROR = 'FETCH_ALL_DEVICES_DISMISS_ERROR';
export const UPDATE_DEVICE_DATA_BEGIN = 'UPDATE_DEVICE_DATA_BEGIN';
export const UPDATE_DEVICE_DATA_SUCCESS = 'UPDATE_DEVICE_DATA_SUCCESS';
export const UPDATE_DEVICE_DATA_FAILURE = 'UPDATE_DEVICE_DATA_FAILURE';
export const UPDATE_DEVICE_DATA_DISMISS_ERROR = 'UPDATE_DEVICE_DATA_DISMISS_ERROR';
export const FETCH_DEVICE_BEGIN = 'FETCH_DEVICE_BEGIN';
export const FETCH_DEVICE_SUCCESS = 'FETCH_DEVICE_SUCCESS';
export const FETCH_DEVICE_FAILURE = 'FETCH_DEVICE_FAILURE';
export const FETCH_DEVICE_DISMISS_ERROR = 'FETCH_DEVICE_DISMISS_ERROR';
export const SHARE_DEVICE = 'SHARE_DEVICE';
export const MANUAL_REFRESH = 'MANUAL_REFRESH';
export const EDIT_SUMMARY_CELL = 'EDIT_SUMMARY_CELL';
export const GET_DEVICE_BEGIN = 'GET_DEVICE_BEGIN';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_FAILURE = 'GET_DEVICE_FAILURE';
export const GET_DEVICE_DISMISS_ERROR = 'GET_DEVICE_DISMISS_ERROR';
export const SET_DASHBOARD_DEVICE = 'SET_DASHBOARD_DEVICE';
export const FETCH_FORECAST_BEGIN = 'FETCH_FORECAST_BEGIN';
export const FETCH_FORECAST_CLEAR = 'FETCH_FORECAST_CLEAR';
export const FETCH_FORECAST_SUCCESS = 'FETCH_FORECAST_SUCCESS';
export const FETCH_FORECAST_FAILURE = 'FETCH_FORECAST_FAILURE';
export const FETCH_FORECAST_DISMISS_ERROR = 'FETCH_FORECAST_DISMISS_ERROR';
export const SET_MAP_VISIBLE_DEVICES = 'SET_MAP_VISIBLE_DEVICES';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const FOCUS_DEVICE = 'FOCUS_DEVICE';
export const HOVER_DEVICE = 'HOVER_DEVICE';
export const SET_THING = 'SET_THING';
