import React from 'react';
import Autocomplete from 'react-google-autocomplete'
import { useSetOnline } from './redux/hooks'
// import PropTypes from 'prop-types';

export default function Autocomplete2 (props) {
  const { online } = useSetOnline()
  if (!online || !window.google) return null
  return <Autocomplete {...props} />
}

Autocomplete.propTypes = {};
Autocomplete.defaultProps = {};

Autocomplete.displayName = 'Autocomplete'

Autocomplete.displayName = 'Autocomplete'

Autocomplete.displayName = 'Autocomplete'

Autocomplete.displayName = 'Autocomplete'

Autocomplete.displayName = 'Autocomplete'

Autocomplete.displayName = 'Autocomplete'

Autocomplete.displayName = 'Autocomplete'
