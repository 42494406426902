import PropTypes from 'prop-types';
import React, { Component } from 'react';
import bindUserActions from './redux/bindUserActions';
import HandlesProps from '../../common/handlesProps'
import { getUrlQueryParam, isOauth, isLoggedIn } from '../../common/ambient'
import { authManagement } from '../../common/feathers'
import { withRouter } from 'react-router'
import OauthTile from './OauthTile'
import {
  HelpLinks
} from '../../components/'

export class Signin extends HandlesProps(Component) {
  static propTypes = {
    user: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      forgot: false,
      email: '',
      resent: false
    }
    this.submit = ::this.submit
  }

  _macAddress () {
    return getUrlQueryParam(this.props.location, 'macAddress') || getUrlQueryParam(this.props.location, 'mac')
  }

  _ref () {
    return getUrlQueryParam(this.props.location, 'ref')
  }

  submit(e) {
    e.preventDefault()
    let { forgot, email } = this.state
    if (forgot) {
      if (email) {
        this.setState({
          resetSent: 'sending' 
        })
        authManagement.sendResetPwd({ email })
        .then((what) => {
          this.setState({
            resetSent: 'success' 
          })
        })
        .catch((what) => {
          this.setState({
            resetSent: 'fail' 
          })
        })
        this.setState({
          resetSent: true
        })
      }
    } else {
      this.state.submitted = true
      this.props.actions.fetchUser(this.state)
    }
  }

  handleLoggedInUser(user) {
    const { location } = this.props
    if (isLoggedIn(user) && !isOauth(location)) {
      let goTo = '/dashboard'
      if (this._macAddress() || this._ref()) {
        goTo = '/devices?'
        if (this._macAddress()) {
          goTo += `macAddress=${this._macAddress()}`
        }
        if (this._ref()) {
          goTo += `&ref=${this._ref()}`
        }
      }
      this.props.history.replace(goTo)
    }
  }

  componentWillMount() {
    this.props.actions.fetchUser()
  }

  componentDidMount() {
    this.handleLoggedInUser(this.props.user)
  }

  componentWillUpdate(nextProps) {
    this.handleLoggedInUser(nextProps.user)
  }

  render() {
    const { location, user, actions } = this.props
    const { resent, forgot, resetSent, email } = this.state
    let error
    if (this.state.submitted && user.fetchUserError) {
      const message = /quota has been/.test(user.fetchUserError.message) ? 'Sorry, sign-in will not work in "Private Browsing" mode' : user.fetchUserError.message
      error = <div className="error">{message}</div>
    }
    const signin = this.state.submitted && !user.fetchUserError ? 'Signing in...' : 'Sign in'
    const form =
      <div>
        <div className="row">
          <label>Email</label>
          <input type="email" autoComplete="email" onChange={evt => this.setState({ email: evt.target.value.toLowerCase() })} value={this.state.email} />
        </div>
        <div className={forgot ? 'hidden' : 'row'}>
          <label>Password</label>
          <input type="password" autoComplete="current-password" onChange={this.handleChangeProp('password')} defaultValue={this.state.password} />
        </div>
        {error}
        <input className="btn btn-lg btn-primary" type="submit" value={forgot ? 'Reset Password' : signin} />
        <p className="text-center">
          <a
            onClick={() => this.setState({ forgot: !forgot })}
          >
            {forgot ? 'I remember it' : 'Forgot your password?'}
          </a>
        </p>
      </div>
    let passwordMessage = <p>One sec...</p>
    if (resetSent === 'success' || resetSent === 'fail') {
      passwordMessage = (
        <>
          <p>Check your inbox, bulk or spam folder for a password reset email.</p>
          <p>
            If you dont see it. Check your inbox or spam folder for an email with the subject line "Confirm your account". Once you've confirmed you'll be able to reset your password.  Sorry for the inconvenience.
          </p>
          {resent 
            ? <p>Sent! Check your inbox or spam folder for a new confirmation email. If you still don't see it you probably signed up with an email address other than "{email}". </p> 
            : <p>Still can't find it? <a onClick={() => {
                actions.sendConfirmation({ user: email, confirmKey: true })
                this.setState({ resent: true })
            }}>Resend</a> the confirmation email to <b>"{email}"</b></p>
          }
        </>
      ) 
    }
    let whatToShow =
      <form onSubmit={this.submit}>
        {resetSent ? passwordMessage : form }
      </form>
    if (isLoggedIn(user) && isOauth(location)) {
      whatToShow = <OauthTile location={location} />
    }
    return (
      <div className="user-signin form-center">
        {whatToShow}
        <HelpLinks />
      </div>
    );
  }
}

export default bindUserActions(withRouter(Signin))

Signin.displayName = 'Signin'
