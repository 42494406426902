import React from 'react';
import { path } from 'ramda'
import { setStorage, getStorage, clearStorage, errorLog, guid, isIos, isLoggedIn, reloadApp } from '../common/ambient';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { 
      hasError: true,
      errorId: guid()
    }
  }

  componentDidCatch (error, errorInfo) {
    const { user, location } = this.props
    const { errorId } = this.state
    const beenHereBefore = getStorage('error-boundry')
    if (!beenHereBefore) {
      console.log('reloding')
      setStorage('error-boundry', true)
      errorLog({ errorInfo, user: user.info, location: location.pathname, device: window.device, resolution: 'refresh', version: window._ambientVersion })
      reloadApp()
      return
    }
    const email = path(['info', 'email'], user)
    errorLog({ errorId, errorInfo, user: user.info, location: location.pathname, device: window.device, resolution: 'whitescreen', version: window._ambientVersion  })
    if (email) {
      errorLog(`whitescreen email,${email}`)
    }
  }

  render () {
    const { user, userActions, children } = this.props
    const { errorId, hasError } = this.state
    if (hasError) {
      const code = errorId.split('-')[1]
      // You can render any custom fallback UI
      return (
        <div className='app offline error'>
          <p>uh oh</p>
          <div className='icon' />
          <div className='error-description'>
            <p>Something went wrong,<br/>but we've logged the error.</p>
            <p>
              Sorry for the trouble.<br/>
              <button className='btn btn-primary' onClick={() => {
                clearStorage()
                if (isLoggedIn(user)) {
                  userActions.logoutUser()
                } else {
                  reloadApp()
                }
              }}>Try Refreshing</button>
            </p>
            <div className='still-having-trouble'>
              <h5>Still here?</h5>
              <p> Some users have had success deleting the app and re-installing it. Make sure to choose "Delete all app data" when you delete it.</p>
              <p>If that doesn't work please help us track this down. Contact <a target='_blank' href={`mailto:support@ambientweather.com?subject=iOS whitescreen [${code}]`}>support@ambientweather.com</a> and include the issue code: <em>{code}</em></p>
            </div>
          </div>
        </div>
      )
    }
    return children;
  }
}
ErrorBoundary.displayName = 'ErrorBoundary'

ErrorBoundary.displayName = 'ErrorBoundary'

ErrorBoundary.displayName = 'ErrorBoundary'

ErrorBoundary.displayName = 'ErrorBoundary'

ErrorBoundary.displayName = 'ErrorBoundary'

ErrorBoundary.displayName = 'ErrorBoundary'

ErrorBoundary.displayName = 'ErrorBoundary'
