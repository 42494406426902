import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda'
import classNames from 'classnames'
import Loader from '../../components/Loader'
import QuestionMark from '../../components/QuestionMark'
import { getThemeObj } from '../../common/skinner.js'

const config = {
  wu: {
    title: 'Weather Underground',
    IDLabel: 'Station ID',
    PASSWORDLabel: 'Station Key',
    link: 'https://www.wunderground.com/personal-weather-station/mypws',
    // TODO Nils Clean up;   // helpLink: 'https://help.ambientweather.net/help/weatherunderground-com/',
    helpLink: getThemeObj().helpUrlBase + 'weatherunderground-com/',
    desc: 'WeatherUnderground.com is a free, public weather data hosting service, and has accumulated the largest number of reporting weather stations in the world.'
  },
  pws: {
    title: 'PWS Weather',
    IDLabel: 'Station ID',
    PASSWORDLabel: 'Password',
    link: 'https://www.pwsweather.com/stationlist.php',
    // TODO Nils Clean up;   // helpLink: 'https://help.ambientweather.net/help/pwsweather/',
    helpLink: getThemeObj().helpUrlBase + 'pwsweather/',
    desc: 'PWSWeather is a free, public weather data hosting service. PWSWeather.com is a program to bring together data from personal weather stations worldwide, display it and make it available for other purposes.'
  }
}

export default class ServersForm extends PureComponent {
  static propTypes = {
    theDevice: PropTypes.object.isRequired,
    service: PropTypes.string.isRequired,
    deviceActions: PropTypes.object.isRequired
  }
  state = {
    open: false
  }
  getField(field) {
    const { theDevice, service } = this.props
    return path(['servers', service, field], theDevice) ? theDevice.servers[service][field] : ''
  }
  getServersInput(field) {
    const { theDevice, service, deviceActions } = this.props
    const val = this.getField(field)
    return (
      <input
        type="text"
        value={val}
        className={'form-control type-servers-' + service + '-' + field}
        disabled={this.getField('verified') === true}
        placeholder={config[service][field + 'Label']}
        onChange={(evt) => {
          const servers = theDevice.servers || {}
          servers[service] = servers[service] || {}
          servers[service][field] = evt.target.value
          deviceActions.patch(theDevice._id, { servers })
        }}
      />
    )
  }
  verify() {
    const { theDevice, service, deviceActions } = this.props
    const servers = theDevice.servers
    servers[service].verify = true
    deviceActions.patch(theDevice._id, { servers })
    .then(deviceActions.fetchDevices)
  }

  render() {
    const { theDevice, deviceActions, service } = this.props
    const { open } = this.state
    const isVerified = this.getField('verified') === true
    const disabled = this.getField('ID') === '' || this.getField('PASSWORD') === '' || isVerified
    let message = ''
    if (service === 'wu') {
      message = <div className="message">
        If you have already entered your device information manually in your console, Observer IP Module, or other module, <b>PLEASE DO NOT RE-ENTER IT HERE</b>
      </div>
    }
    const title = config[service].title
    const link = config[service].link
    let serverForm = <div className="form">
      <span>{config[service].title} Info <QuestionMark link={config[service].helpLink} /></span>
      <div className={classNames("device-servers-form", {
          verified: isVerified,
          verifyError: this.getField('verifyError') !== ''
        })}>
        {this.getServersInput('ID')}
        {this.getServersInput('PASSWORD')}
        {this.getField('verify') === true ?
          <Loader /> :
          <button
            disabled={disabled}
            className="btn btn-primary"
            onClick={this.verify.bind(this)}
          >Connect</button>
        }
      </div>
    </div>

    if (isVerified) {
      serverForm = <div className="form">
        <span>Connected to {title} with Station ID: {this.getField('ID')}</span>
        <button
          className="btn"
          onClick={() => {
            if (window.confirm('Are you sure you want to stop sending data to ' + title + '?')) {
              const servers = theDevice.servers || {}
              servers[service] = {}
              deviceActions.patch(theDevice._id, { servers })
            }
          }}
        >Disconnect</button>
      </div>
    }
    return (
      <div className={classNames("service", { open })}>
        <header onClick={() => {
            this.setState({ open: !open })
          }}>
          {title}
          {service !== 'wu' || isVerified ? <span className={classNames('badge', { on: isVerified })} /> : ''}
        </header>
        <div className="bottom">
          {message}
          {serverForm}
          {this.getField('verifyError') !== '' ? <div className="error">{this.getField('verifyError')}</div> : ''}
          <div className="desc">
            <p>
              {config[service].desc}
            </p>
          </div>
          {isVerified ? '' : <div className="register">
            Register at <a target="_blank" href={link}>{link}</a>
            <a target="_blank" className="instructions" href={config[service].helpLink}>Read detailed instructions here ></a>
          </div>}
        </div>
      </div>
    )
  }
}

ServersForm.displayName = 'ServersForm'

ServersForm.displayName = 'ServersForm'

ServersForm.displayName = 'ServersForm'

ServersForm.displayName = 'ServersForm'

ServersForm.displayName = 'ServersForm'

ServersForm.displayName = 'ServersForm'

ServersForm.displayName = 'ServersForm'
