import React, { PureComponent } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Avatar from '../features/user/Avatar'
import { isAdmin, isRole, isLoggedIn, isDev, hasWxw } from '../common/ambient'
import PropTypes from 'prop-types'
import { split, equals, on, path } from 'ramda'
import {
  ProtectedLink
} from './'
import bindAllActions from '../common/bindAllActions'
import { freeTrialUsed, isPlus, showAwnFreeTrial } from '../features/payment'

class DashboardNav extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    device: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    logout: PropTypes.func
  };

  render () {
    const { user, userActions, onChange, location, device } = this.props
    const adds = []
    const brand = <Link className='navbar-brand' to='/dashboard' />
    const isActive = equals(location.pathname)
    const linkProps = (path) => {
      const ret = {
        to: path,
        className: 'nav ' + path.replace(/\//g, '')
      }
      const pieces = split('/', location.pathname)
      if (isActive(path) ||
        (pieces.length > 2 && pieces.slice(0, 2).join('/') === path) ||
        (/account/.test(path) && /account/.test(location.pathname)) // hack
      ) {
        ret.className += ' active'
      }
      if (onChange) {
        ret.onClick = onChange
      }
      return ret
    }
    adds.push(
      <div key={1}>
          <ProtectedLink {...linkProps('/alerts')}>
            <i className='alerts' />
            <span>Alerts</span>
          </ProtectedLink>
          <ProtectedLink {...linkProps('/account/units')}>
            <i className='settings' />
            <span>Account</span>
          </ProtectedLink>
          <ProtectedLink {...linkProps('/support')}> {/* CSS is used to control visibility */}
            <i className='kmsupport' />
            <span>Support</span>
          </ProtectedLink>
        </div>
    )


    if (isAdmin(user) || isRole('dev', user)) {
      const isAdminPage = /admin/.test(location.pathname)
      adds.push(
        <div key={2}>
          <Link {...linkProps('/admin')}>
            <i className='admin' />
            <span>Admin</span>
          </Link>
        </div>
      )
      if (isAdminPage) {
        adds.push(
          <div key={3}>
            <Link {...linkProps('/admin/social')}>
              <span>Social</span>
            </Link>
          </div>
        )
        adds.push(
          <div key={4}>
            <Link {...linkProps('/admin/data')}>
              <span>Data Sales</span>
            </Link>
          </div>
        )
      }
    }
    let logoutBtn = <a className='logout' href='#' onClick={() => userActions.logoutUser()}><i className='glyphicon glyphicon-off' /> Logout</a>
    if (!isLoggedIn(user)) {
      logoutBtn = <Link className='logout' to='/signin'><i className='glyphicon glyphicon-off' /> Login</Link>
    }
    return (
      <div className='component-dashboard-nav'>
        <div className='wrap'>
          {brand}
          <div className='scroll-wrap'>
            <Link {...linkProps('/account')}>
              <Avatar />
              <div>
                <h4>Hi {path(['info', 'username'], user)}</h4>
                <p>{path(['info', 'email'], user)}</p>
                <button className='btn btn-primary white'>View account</button>
              </div>
            </Link>
            <a
              className='nav plus'
              onClick={() => {
                if (onChange) {
                  onChange()
                }
                userActions.doModal({ type: 'plus-subscription' })
              }}
            />
            {showAwnFreeTrial(user) && (
              <a
                className='trial'
                onClick={() => {
                  if (onChange) {
                    onChange()
                  }
                  userActions.doModal({ type: 'plus-subscription' })
                }}
              >Limited Time Free Trial</a>
            )}
            <Link {...linkProps('/dashboard')}>
              <i className='dashboard' />
              <span>Dashboard</span>
            </Link>
            {hasWxw(device) && (
              <Link {...linkProps('/wxw')}>
                <i className='wxw' />
                <span>Weather<br/>Window</span>
              </Link>
            )}
            <Link {...linkProps('/devices')}>
              <i className='devices' />
              <span>Devices</span>
            </Link>
            {adds}
            <div className='nav-btn'>
              <Link onClick={() => onChange && onChange()} to='/' className='btn btn-primary map'>Weather Map</Link>
            </div>
          </div>
          {logoutBtn}
        </div>
      </div>
    )
  }
}

export default bindAllActions(withRouter(DashboardNav))
DashboardNav.displayName = 'DashboardNav'
