import React, { PureComponent } from 'react'
import { Autocomplete } from '../features/common/'
import PropTypes from 'prop-types'
import { pick, path, prop, nth, identity, filter, pipe, map, find } from 'ramda'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { AmbRadio, FindLocationBtn } from '../components/'
import { getGeocode, METERS_TO_FEET, debounce, pathsChanged, findLocationFromGoogleRes, coordsAreDifferent } from '../common/ambient'

const Geocode = getGeocode()

const isGoodCoords = coords => {
  return coords && coords.lat && coords.lon
}

const MapWithAMarker = withGoogleMap(props => {
  const { coords, onDrag } = props
  let center = { lat: 48.7023029, lng: -113.7515022 }
  if (isGoodCoords(coords)) {
    center = {
      lat: coords.lat,
      lng: coords.lon
    }
  }
  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={center}
      center={center}
    >
      <Marker
        draggable
        onDragEnd={onDrag}
        position={center}
      />
    </GoogleMap>
  )
})

export default class Location extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    location: PropTypes.object
  }

  state = {
    findingLocation: false,
    coords: false,
    elevation: '',
    address: '',
    location: '',
    meters: true,
    showMap: false
  }

  constructor (props) {
    super(props)
    this._onChange = debounce(this._onChange.bind(this), 300)
  }

  componentDidMount () {
    const { location } = this.props
    if (location) {
      this.setState(Object.assign({}, this.state, location))
    }
  }

  _locationNotOk () {
    const { coords } = this.state
    return !(coords && coords.lat && coords.lon)
  }

  _setGoogleResult (theRes, addCoords) {
    if (theRes) {
      const st8 = {
        address: theRes.formatted_address,
        location: findLocationFromGoogleRes(theRes.address_components),
        address_components: theRes.address_components
      }
      if (addCoords) {
        st8.coords = {}
        st8.coords.lat = theRes.geometry.location.lat()
        st8.coords.lon = theRes.geometry.location.lng()
      }
      this.setState(st8)
    }
  }

  _reset () {
    this.setState({
      elevation: '',
      address: '',
      location: '',
      coords: false
    })
  }

  _onChange () {
    const { onChange } = this.props
    if (!onChange) return
    console.log('location changed', this.state)
    onChange(pick(['coords', 'address', 'location', 'elevation', 'address_components'], this.state))
  }

  componentDidUpdate (prevProps, prevState) {
    const { coords, address, elevation, location } = this.state
    if (pathsChanged(this.state, prevState, [['coords']]) && coords) {
      if (address === '') {
        Geocode.fromLatLng(coords.lat, coords.lon)
          .then(res => {
            this._setGoogleResult(res.results[0])
          })
      }
      if (coords && coords.lat && coords.lon) {
        const elevator = new google.maps.ElevationService()
        elevator.getElevationForLocations({
          locations: [{
            lat: coords.lat,
            lng: coords.lon
          }]
        }, (elevRes, status) => {
          if (elevRes[0]) {
            this.setState({
              elevation: elevRes[0].elevation
            })
          }
        })
      }
    }
    if (pathsChanged(this.props, prevProps, [['location']])) {
      const newLoc = Object.assign({}, this.state, this.props.location)
      if (path(['address'], this.props.location) !== address) {
        newLoc.address = path(['address'], this.props.location) || ''
      }
      this.setState(newLoc)
    }
    if (pathsChanged(this.state, prevState, [['coords'], ['elevation'], ['location']]) && coordsAreDifferent(this.state.coords, path(['location', 'coords'], this.props))) {
      this._onChange()
    }
  }

  onPlaceSelected (place) {
    this._setGoogleResult(place, true)
  }

  onDrag (evt) {
    this.setState({
      coords: {
        lat: evt.latLng.lat(),
        lon: evt.latLng.lng()
      },
      address: ''
    })
  }

  _map () {
    const { coords } = this.state
    return (
      <MapWithAMarker
        coords={coords}
        onDrag={this.onDrag.bind(this)}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    )
  }

  render () {
    const { showMap, meters, elevation, coords, address, findingLocation } = this.state
    const { onSave } = this.props
    const elevationToShow = meters ? elevation : METERS_TO_FEET * elevation
    let bottom = (
      <div className="show-map">
        <div className="map">
          <p>Move Pin to set the correct location:</p>
          {this._map()}
        </div>
        <div className="middle">
          <div className="item">
            <span>Latitude</span>
            <input type="text" value={coords.lat || ''} onChange={evt => {
              const c = Object.assign({}, coords) || {}
              c.lat = parseFloat(evt.target.value)
              this.setState({ coords: c })
            }} />
          </div>
          <div className="item">
            <span>Longitude</span>
            <input type="text" value={coords.lon || ''} onChange={evt => {
              const c = Object.assign({}, coords) || {}
              c.lon = parseFloat(evt.target.value)
              this.setState({ coords: c })
            }} />
          </div>
          <div className="item">
            <span>Elevation</span>
            <input type="text" value={elevationToShow} onChange={evt => this.setState({ elevation: meters ? evt.target.value : evt.target.value / METERS_TO_FEET })} />
            <div className="units">
              <AmbRadio
                selected={meters}
                label="meters"
                onClick={() => this.setState({ meters: true })}
              />
              <AmbRadio
                selected={!meters}
                label="feet"
                onClick={() => this.setState({ meters: false })}
              />
            </div>
          </div>
        </div>
        <p><a onClick={() => this.setState({ showMap: false })}>Hide map view</a></p>
        <div className="btns-wrap">
          <a className="skip" onClick={() => {
              if (onSave) {
                onSave(null)
              }
            }} >Don't Set Location</a>
          <button className="btn btn-primary btn-long" onClick={() => {
            if (onSave) {
              onSave(pick(['coords', 'address', 'location', 'elevation'], this.state))
            }
          }} key="set" disabled={this._locationNotOk()}>Set Location</button>
        </div>
      </div>
    )
    if (!showMap) {
      bottom = (
        <p>
          <a onClick={() => this.setState({ showMap: true })}>Select location from map view</a>
        </p>
      )
    }
    return (
      <div className='component-location'>
        <div className='top'>
          <div className='autocomplete-wrap'>
            <Autocomplete
              value={address}
              types={[]}
              type='text'
              className='autocomplete'
              placeholder='Street Address or Region'
              onPlaceSelected={this.onPlaceSelected.bind(this)}
              onChange={evt => {
                this.setState({
                  address: evt.target.value
                })
              }}
            />
            {address === '' ? null : <a onClick={this._reset.bind(this)} className='glyphicon x-gray' />}
          </div>
          <span>Or</span>
          <FindLocationBtn
            onClick={() => this.setState({ address: '' })}
            onChange={position => {
              this.setState({
                coords: {
                  lat: position.coords.latitude,
                  lon: position.coords.longitude
                }
              })
            }}
          />
        </div>
        {bottom}
      </div>
    )
  }
}

Location.displayName = 'Location'
