import PropTypes from 'prop-types'
import { omit } from 'ramda'
import React, { PureComponent } from 'react'
import { coordsAreDifferent, coordString, getForecastParams, isLoggedIn, pathsChanged } from '../common/ambient'
import bindAllActions from '../common/bindAllActions'

const MAX_AGE = 1000 * 60 * 5 // 5 mins

class FetchesForecast extends PureComponent {
  static propTypes = {
    coords: PropTypes.array,
    update: PropTypes.bool,

    device: PropTypes.object,
    deviceActions: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      lastFetch: Date.now()
    }
  }

  componentDidMount () {
    this._fetchForecast()
  }

  componentDidUpdate (prevProps) {
    const { device, coords, update } = this.props
    const { lastFetch } = this.state
    const { forecastCache } = device
    const { coords: prevCoords } = prevProps
    const forecast = forecastCache[coordString(coords[1], coords[0])]
    const shouldFetch = pathsChanged(prevProps, this.props, [
      ['update'],
      ['user', 'userChecked']
    ]) ||
      !forecast ||
      coordsAreDifferent(coords, prevCoords) ||
      Date.now() - lastFetch > MAX_AGE ||
      (pathsChanged(prevProps, this.props, [['device', 'appPaused']]) && !this.props.device.appPaused)
    if (shouldFetch && coords && update !== false) {
      this._fetchForecast()
    }
  }

  _fetchForecast () {
    const { device, coords, deviceActions, update, user } = this.props
    const { fetchForecastPending } = device
    if (coords && !fetchForecastPending && coords[0] && coords[1]) {
      this.setState({ lastFetch: Date.now() }, () => {
        const args = {
          lat: coords[1],
          lon: coords[0],
          force: update
        }
        if (isLoggedIn(user) && getForecastParams(user).includes('Pressure')) {
          args.hourlyPressure = true
        }
        deviceActions.fetchForecast(args)
      })
    }
  }

  render () {
    const { coords, device } = this.props
    const { forecastCache } = device
    if (!coords) return null
    const forecast = forecastCache[coordString(coords[1], coords[0])]
    return (
      <div className='component-fetches-forecast'>
        {React.cloneElement(this.props.children, { forecast, ...omit(['device', 'deviceActions'], this.props) })}
      </div>
    )
  }
}

export default bindAllActions(FetchesForecast)
FetchesForecast.displayName = 'FetchesForecast'
