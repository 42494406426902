import React, { PureComponent } from 'react';
import classNames from 'classnames'
import Select from 'react-select'
import { withRouter } from 'react-router';
import bindAllActions from '../common/bindAllActions'
import { formatCoords, cleanDeviceToStore, getGeocode, getUserSetting, getUserUnitI, objsContainId, getDeviceLabel, latLonDistance, getTheDevice, shouldGetDevice, isLoggedIn, isDev, pathsChanged, setStorage, getStorage, getDeviceIdFromUrl, showDeviceOnMap, getDeviceSlug, findLocationFromGoogleRes, isWxw } from '../common/ambient'
import { Autocomplete } from '../features/common'
import {
  Loader,
  FindLocationBtn,
  FavStar
} from '../components'
import PropTypes from 'prop-types';
import { pluck, propEq, filter, slice, concat, prop, uniqBy, pick, contains, pipe, values, nth, path, isEmpty, find, identity } from 'ramda'

const Geocode = getGeocode()

// reported in last day
const reportedRecentEnough = d => {
  if(!d.lastData) {
    return false
  }
  return Date.now() - d.lastData.dateutc < 1000 * 60 * 60 * 24
}


class DeviceChooser extends PureComponent {
  static propTypes = {
    open: PropTypes.bool,
    skipIpSearch: PropTypes.bool,
    hideSearch: PropTypes.bool,
    placholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onLocationChange: PropTypes.func,
    onSearch: PropTypes.func,
    onFindLocation: PropTypes.func,

    device: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    deviceActions: PropTypes.object
  }
  state = {
    open: false,
    address: '',
    showMyStationsTab: true,
    page: 1,
    userRecentI: 0
  }
  constructor(props) {
    super(props)
    this.state.open = this.props.open
    this._esc = ::this._esc
    this._toggleOpen = ::this._toggleOpen
    this._favStar = ::this._favStar
    this._reset = ::this._reset
  }
  _isHomePage() {
    return this.props.open
  }
  _setDefaultDevice() {
    const { history, deviceActions, open, skipIpSearch, device, user, userActions, match } = this.props
    const { dashboardDevice, devices, getDeviceFailures, fetchDevicePending } = device
    const { fetchInfoPending, fetchInfoError, ipInfo, userChecked } = user
    const { firstSearch, theDevice, userRecentI } = this.state
    const favs = this._userFavs()


    // first time here
    if (this._isHomePage()) {
      const lastLocationSearch = getStorage('lastLocationSearch')
      if (lastLocationSearch) {
        if (!firstSearch) {
          this.setState({
            firstSearch: true,
            manualSearch: true
          }, () => {
            this._doSearch([lastLocationSearch.coords.lon, lastLocationSearch.coords.lat])
          })
        }
        return
      }
    // not homepage
    } else {
      if (dashboardDevice) return
      const slug = path(['params', 'key'], match) 
      if (slug) {
        const myDevice = devices && devices.find(d => getDeviceSlug(d) === slug)
        if (myDevice) {
          this._onChange(myDevice)
        } else if (!fetchDevicePending) {
          deviceActions.fetchDevice({ 'public.slug': slug })
            .then(res => {
              deviceActions.clearFetchedDevices()
              if (res.data[0]) {
                this._onChange(res.data[0])
              } else if (userChecked) {
                history.push('/dashboard')
              }
            })
        }
        return
      }
      // default to their most recent 
      if (this._userRecent() && this._userRecent()[userRecentI]) {
        // if this is their device, we need to use the current state of the device
        let recentDevice = this._userRecent()[userRecentI]
        const myDevice = devices && find(propEq('macAddress', recentDevice.macAddress), devices)
        return this._onChange(myDevice || recentDevice)
      }
      // or their first favorite
      if (favs) {
        const favToChoose = pipe(
          pluck('to'),
          filter(d => !contains(d._id, getDeviceFailures)), // remove devices no longer public
          nth(0)
        )(favs)
        if (favToChoose) {
          return this._onChange(favToChoose)
        }
      }
      // or their first device
      if (devices && devices[0]) {
        this._onChange(devices[0])
      }
    }

    // we fetched their location from IP 
    // this currently happens in userListener
    if (ipInfo) {
      if (!firstSearch) {
        this.setState({
          firstSearch: true
        }, () => {
          this._doSearch([ipInfo.longitude, ipInfo.latitude], this._isHomePage() ? '' : ipInfo.city)
            .then(searchDevices => {
              // choose the first result
              this._onChange(searchDevices[0])
            })
        })
      }

    // we have nothing else to go on so fetch location from IP
    } else if (!fetchInfoPending && !fetchInfoError && !skipIpSearch) {
      userActions.fetchInfo()
    }
  }
  componentDidMount() {
    const { onRef } = this.props
    this._setDefaultDevice()
    if (onRef) {
      onRef(this)
    }
  }
  componentWillUnmount() {
    const { onRef } = this.props
    if (onRef) {
      onRef(undefined)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { deviceActions, onLocationChange, device, match } = this.props
    const { getDeviceFailures, dashboardDevice } = device
    this._setDefaultDevice()
    const slug = path(['params', 'key'], match)
    // this was removed to prevent refresh when clicking between stations on dash map
    // logic was added to DevicePopup's view dash button instead 4.15.22 olw
    // if (dashboardDevice && slug && getDeviceSlug(dashboardDevice) !== slug) {
      // deviceActions.setDashboardDevice(null)
    // }
    if (pathsChanged(this.state, prevState, [
      'address',
      'coords'
    ]) && onLocationChange) {
      onLocationChange({
        address: this.state.address,
        coords: this.state.coords
      })
    }
  }
  _onChange (d) {
    const { commonActions, deviceActions, userActions, user, onChange } = this.props
    deviceActions.setDashboardDevice(d)
    if (isLoggedIn(user) && d) {
      // save recent
      let recent = this._userRecent() || []
      const cleanDevice = cleanDeviceToStore(d)
      if (path(['info'], cleanDevice)) {
        recent.unshift(cleanDevice)
        recent = uniqBy(prop('_id'), recent).slice(0, 3)
        userActions.updateSetting('recent', recent)
      }
    }
    if (onChange) {
      onChange(d)
    }
    const coords = path(['info', 'coords'], d)
    if (coords) {
      commonActions.setMapLocation(coords)
    }
  }
  _doSearch(coords, address) {
    const { hideSearch, onLocationChange, onSearch, deviceActions } = this.props
    const { page, searchDevices, manualSearch }  = this.state
    this.setState({
      address,
      coords,
      searching: true
    })
    const perPage = 15
    const args = {
      $publicNear: {
        coords
      },
      $limit: perPage,
      skipCache: true
    }
    if (page > 1) {
      args.$skip = perPage * (page - 1)
    }
    return deviceActions.fetchDevice(args)
      .then(res => {
        if(!res) return
        let newSearchDevices = (page > 1 ? concat(searchDevices, res.data) : res.data)
        newSearchDevices = newSearchDevices.filter(showDeviceOnMap)
        if (onSearch) {
          onSearch(newSearchDevices, { searched: manualSearch })
        }
        const st8 = {
          searchDevices: newSearchDevices,
          searching: false
        }
        if (hideSearch) {
          delete st8.searchDevices
        }
        this.setState(st8)
        if (this.selectRef) {
          this.selectRef.focus()
        }
        return newSearchDevices
      })
  }
  _onPlaceSelected(place, showAddress = true) {
    const { commonActions } = this.props
    if (!place.geometry) {
      return
    }
    const ifFunc = sayWhat => {
      return typeof sayWhat === 'function' ? sayWhat() : sayWhat
    }
    const coords = [ifFunc(place.geometry.location.lng), ifFunc(place.geometry.location.lat)]
    // save this search in localStorage
    const formattedPlace = formatCoords({
      address: place.formatted_address,
      label: place.formatted_address,
      location: findLocationFromGoogleRes(place.address_components)
    }, {
      lat: coords[1],
      lon: coords[0]
    }) 
    commonActions.setMapLocation(formattedPlace)
    this.setState({
      page: 1,
      manualSearch: true // we set this when the user has searched once
    }, () => this._doSearch(coords, showAddress ? place.formatted_address : null))
  }
  searchByPosition(position) {
    let coords
    if (Array.isArray(position)) {
      coords = {
        lon: position[0],
        lat: position[1]
      }
    } else {
      coords = position.coords
    }
    Geocode.fromLatLng(coords.latitude || coords.lat, coords.longitude || coords.lon)
    .then(res => {
      this._onPlaceSelected(res.results[0], false)
    })
  }
  _esc (evt) {
    if (evt.keyCode === 27) {
      this.setState({ open: false })
      this._reset()
    }
  }
  _reset() {
    this.setState({
      coords: null,
      address: '',
      searchDevices: false
    })
  }
  _userFavs() {
    if (!this.props.social.favs) return false
    const myMacs = pluck('macAddress', (this.props.device.devices || []))
    return (this.props.social.favs || [])
      .filter(f => f.to.type === 'device' && !myMacs.includes(f.to.macAddress))
  }
  _refreshFavs() {
    const { device, deviceActions } = this.props
    // refresh favs
    const favs = this._userFavs()
    if (favs) {
      favs.forEach(f => {
        const d = f.to
        if (shouldGetDevice(device, d._id, 1)) {
          deviceActions.getDevice(d._id)
        }
      })
    }
  }
  _userRecent() {
    const recent = getUserSetting('recent')(this.props.user)
    return recent || false
  }
  _favStar(currentDevice) {
    if (!currentDevice) return
    return <FavStar currentDevice={currentDevice} />
  }
  formatOptionLabel(opt, ctx) {
    const { user, device } = this.props
    const { coords } = this.state
    const { deviceCache } = device
    let theDevice = opt.d
    if (!theDevice) {
      return <div className={classNames({ load: opt.loadMore, help: opt.help })}>{opt.component || opt.label}</div>
    }
    // my favs should be in the deviceCache
    const favDevice = deviceCache[theDevice._id]
    if (favDevice) {
      theDevice = favDevice
    }
    const deviceGeo = path(['info', 'coords', 'geo'], theDevice)
    let dist
    if (coords && deviceGeo) {
      const optLat = path(['d', 'info', 'coords', 'geo', 'coordinates', 1], opt) || path(['d', 'info', 'coords', 'coords', 'lat'], opt)
      const optLon = path(['d', 'info', 'coords', 'geo', 'coordinates', 0], opt) || path(['d', 'info', 'coords', 'coords', 'lon'], opt)
      const distLabel = getUserUnitI('metric', user) === 1 ? latLonDistance(coords[1], coords[0], optLat, optLon, 'K').toFixed(1) + ' km' : latLonDistance(coords[1], coords[0], optLat, optLon).toFixed(1) + ' mi'
      dist = <span className="dist">{distLabel}</span>
    }
    let t 
    // my devices have lastData
    if (theDevice.lastData) {
      t = moment(new Date(theDevice.lastData.dateutc))
    }
    let subtitle = <div className={classNames("subtitle", { old: !t || Date.now() - t.valueOf() > 1000 * 60 * 60 * 24 })}>{t ? 'updated ' +  t.from(Date.now()) : 'not reporting...' }</div>
    let favStar 
    if (opt.search || opt.fav) {
      favStar = this._favStar(theDevice)
    }
    // fav that's no longer public
    if (opt.fav && opt.disabled) {
      subtitle = <a onClick={() => {
        this._doSearch(theDevice.info.coords.geo.coordinates, theDevice.info.coords.location)
      }} className="subtitle">Find nearby</a>
    }
    if (opt.recent || opt.search) {
      subtitle = null
    }
    const label = /noaa-/.test(theDevice.macAddress) ? <span><span className="noaa">NOAA: </span>{opt.label}</span> : <span>{opt.label}</span>
    return <div 
      className={classNames("device-option", { 
        favable: favStar, 
        disabled: opt.disabled,
        recent: opt.recent,
        search: opt.search
    })}>
      {favStar}
      <div className="option-label">{label}{subtitle}</div>
      {dist}
    </div>
  }
  formatGroupLabel(group) {
    if (group.label === 'stations') {
      const { showMyStationsTab } = this.state
      return <div className="tabs">
        <a onClick={() => this.setState({ showMyStationsTab: true })} className={classNames({ active: showMyStationsTab })}>My Stations</a>
        <a onClick={() => this.setState({ showMyStationsTab: false })} className={classNames({ active: !showMyStationsTab })}>Favorites</a>
      </div>
    }
    return group.label
  }
  _toggleOpen() {
    const { devices } = this.props.device
    const { open } = this.state
    const st8 = { open: !open }
    // closing
    if (open) {
      document.removeEventListener('keydown', this._esc, false)
    // opening
    } else {
      document.addEventListener('keydown', this._esc, false)
      this._refreshFavs()
      // first time opening
      if (!this.hasBeenOpened) {
        this.hasBeenOpened = true
        // if they dont have devices show their favs tab 
        const favs = this._userFavs()
        if (favs && favs.length > 0 && (!devices || devices.length < 1)) {
          st8.showMyStationsTab = false
        }
      }
    }
    this.setState(st8)
  }
  _isMyDevice(deviceId) {
    return objsContainId(this.props.device.devices, deviceId)
  }

  _open() {
    const { selectValue, searching, showMyStationsTab, coords, searchDevices, open, address } = this.state
    const { onFindLocation, user, device } = this.props
    const { deviceCache, devices, getDeviceFailures } = device
    if (!open) return

    const deviceToOption = d => ({
      label: getDeviceLabel(d),
      d,
      value: d._id
    })
    let myDevices = (devices || []).filter(d => !isWxw(d)).map(deviceToOption)
    const favs = this._userFavs()
    // show favs
    if (!showMyStationsTab) {
      myDevices = favs ? favs.map(f => {
        const d = deviceCache[f.to._id]
        if (!d) return
        return {
          label: getDeviceLabel(d),
          d,
          fav: true,
          value: d._id,
          disabled: contains(d._id, getDeviceFailures)
        }
      }).filter(identity) : []
    }
    // filter if they're searching
    if (coords) {
      myDevices = myDevices.filter(obj => {
        const deviceGeo = path(['d', 'info', 'coords', 'geo'], obj)
        if (!deviceGeo) return
        const distance = latLonDistance(coords[1], coords[0], deviceGeo.coordinates[1], deviceGeo.coordinates[0])
        return distance < 100
      })
    }
    const options = []
    // no favs & no my devices
    if (myDevices.length < 1) {
      if (showMyStationsTab) {
        if ((searchDevices || searching) && (devices && devices.length > 0)) {
          myDevices = [{ label: <span>You have no stations at this location<br/><a onClick={this._reset}>show my devices</a></span>, disabled: true, help: true }]
        } else {
          myDevices = [{
            component: <div className="cta">
              <img src="https://ambientweather.net/my-stations.jpg" />
              <a href="https://www.amazon.com/stores/page/EAA7AF90-380A-4AEA-A8A9-F4E0368510BB" target="_blank" className="btn btn-circle">Shop Stations Now</a>
            </div>,
            disabled: true
          }]
        }
      } else {
        myDevices = [{ label: 'Star a station to add to your favorites', disabled: true, help: true }]
      }
    }
    options.push({
      label: 'stations',
      options: myDevices
    })
    const recent = this._userRecent()
    let searchOptions
    if (searchDevices) {
      searchOptions = searchDevices.map(deviceToOption).map(d => Object.assign(d, { search: true }))
      if (searchOptions.length > 0) {
        searchOptions.push(searching
          ? { loadMore: true, component: <Loader />, disabled: true } 
          : { label: 'Load more', loadMore: true })
      }
      options.push({
        label: 'Nearby stations',
        options: searchOptions 
      })
    } else if (recent) {
      options.push({
        label: 'Recently Viewed',
        options: recent.map(d => {
          return {
            label: getDeviceLabel(d),
            d,
            recent: true,
            value: d._id,
            disabled: contains(d._id, getDeviceFailures)
          }
        })
      })
    }

    return <div className="open">
      <div className="search">
        <Autocomplete
          value={address || ''}
          types={[]}
          type="text"
          className="autocomplete"
          placeholder={this.props.placeholder || "Search location"}
          onPlaceSelected={this._onPlaceSelected.bind(this)}
          onFocus={() => this.setState({ address: '' })}
          onChange={evt => {
            this.setState({
              address: evt.target.value
            })
          }}
        />
        {address && !searching ? <a className="glyphicon x-gray" onClick={() => this.setState({
          address: '',
          coords: null,
          searchDevices: null
        })} /> : null}
        {searching ? <Loader /> : null}
      </div>
      <Select
        className="device-select"
        classNamePrefix="dc"
        options={options}
        value={selectValue}
        menuIsOpen={true}
        ref={node => this.selectRef = node}
        formatOptionLabel={this.formatOptionLabel.bind(this)}
        formatGroupLabel={this.formatGroupLabel.bind(this)}
        noOptionsMessage={what => {
          return 'Search by location to find a station nearby.'
        }}
        onChange={selected => {
          if (selected.loadMore) {
            const { page, coords, address } = this.state
            this.setState({
              page: page + 1,
              selectValue: null 
            }, () => this._doSearch(coords, address))
            return
          }
          this.setState({
            selectValue: selected
          })
          this._onChange(selected.d)
          // always open
          if (this.props.open) {
            this._reset()
          } else {
            this._toggleOpen()
          }
        }}
        isOptionDisabled={({ disabled }) => disabled}
        maxMenuHeight={400}
      />
      <FindLocationBtn onChange={location => {
        if (onFindLocation) {
          onFindLocation(location)
        }
        this.searchByPosition(location)
      }} />
    </div>
  }

  render() {
    const { manualSearch, open, searchDevices } = this.state
    const { className, device } = this.props
    const { dashboardDevice } = device
    return (
      <div className={classNames("component-device-chooser", className, { 
          open, 
          sd: searchDevices,
          searched: manualSearch,
          'not-searched': !manualSearch
        })}>
        <div className="spacer" />
        <div className="wrap">
          <div className="header-wrap">
            {this._favStar(dashboardDevice)}
            <a onClick={this._toggleOpen} className="header">{getDeviceLabel(dashboardDevice) || <Loader />}</a>
          </div>
          {this._open()}
        </div>
      </div>
    )
  }
}

export default bindAllActions(withRouter(DeviceChooser))

DeviceChooser.displayName = 'DeviceChooser'
