import React, { useRef, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { SubscribeProcess, awnPlusAnnualMonthlySwitchAvail, freeTrialUsed, getAwnPlusAppPrice, showAwnFreeTrial, subIsAndroid, subIsIap, subIsIos, subIsMonthly } from './';
import { Link } from 'react-router-dom';
import Switch from 'react-switch'
import classNames from 'classnames';
import { filter, intersection, map, path, pipe } from 'ramda';
import { useDoModal } from '../user/redux/doModal';
import bindDeviceActions from '../device/redux/bindDeviceActions';
import { AmbRadio } from '../../components';
import { isApp } from '../../common/ambient';


function PlusSubscriptionModal ({ user, message, device, actions }) {
  const [annual, setAnnual] = useState(true)
  const [step, setStep] = useState(1)
  const [plusUserSwitch, setPlusUserSwitch] = useState(false)
  const { doModal } = useDoModal()
  const userSubscriptionsRef = useRef(null)
  const { paymentComplete } = device
  useEffect(() => {
    actions.setThing('paymentComplete', false)
  }, [actions])
  useEffect(() => {
    const awnsOnly = pipe(
      filter(sub => /^awn-plus-/.test(sub.productName)),
      map(sub => sub.productName)
    )
    const currentSubscriptions = awnsOnly(path(['info', 'payments', 'subscriptions'], user) || [])
    const lastSubscriptions = awnsOnly(userSubscriptionsRef.current || [])
    if (plusUserSwitch && intersection(currentSubscriptions, lastSubscriptions).length !== currentSubscriptions.length) {
      setPlusUserSwitch(false)
    }
    userSubscriptionsRef.current = path(['info', 'payments', 'subscriptions'], user) || []
  }, [user, plusUserSwitch, setPlusUserSwitch])
  let termType = null
  const term = annual ? 'yearly' : 'monthly'
  const freeTrialAvail = showAwnFreeTrial(user)
  const active = false
  const btnLabel = freeTrialAvail ? 'Try 1 month free' : 'Upgrade'
  const monthlyPrice = getAwnPlusAppPrice(device, false) ? getAwnPlusAppPrice(device, false) + ' /mo' : '$4.99/mo'
  const annualMonthlyPrice = getAwnPlusAppPrice(device, true) ? getAwnPlusAppPrice(device, true) + ' /yr' : <>$4.16/mo<sup>*</sup></>
  let price = annual ? annualMonthlyPrice : monthlyPrice
  let showLegend = true
  if (getAwnPlusAppPrice(device, annual)) {
    price = getAwnPlusAppPrice(device, annual) + ' /' + (annual ? 'yr' : 'mo')
    showLegend = false
  }
  const showPlans = (
    <>
      <div className='product-tiers'>
        <div className={classNames(`tier tier-0 step-${step}`, { active, annual })}>
          <div className='top'>
            <h3>Standard</h3>
            <h2>Free</h2>
            <p>&nbsp;</p>
          </div>
          <div className='bottom'>
            <strong>Features</strong>
            <div className='group'>
              <div className='line'>
                <div className='label'>Map Layers</div>
                <div className='val'>
                  Radar, Temperature, Wind and Lightning Layers
                </div>
              </div>
              <div className='line'>
                <div className='label'>Notifications</div>
                <div className='val'>
                  E-mail & Push Alerts
                </div>
              </div>
              <div className='line'>
                <div className='label'>Hourly Forecast</div>
                <div className='val'>
                  3 Days
                </div>
              </div>
              <div className='line'>
                <div className='label'>Data History</div>
                <div className='val'>
                  1 Year
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(`tier tier-1 step-${step}`, { active, annual, trial: freeTrialAvail })}>
          <div className='top'>
            <h3>AWN+</h3>
            {!awnPlusAnnualMonthlySwitchAvail(user) && (
              <div className={classNames('switch-wrap', annual ? 'annual' : 'monthly')}>
                <div className={classNames('switch-container', annual ? 'annual' : 'monthly')}>
                  <span className='slider' />
                  <button
                    className={`toggle-btn ${annual ? 'active' : ''}`}
                    onClick={() => setAnnual(true)}
                  >
                    Annual
                    <div>
                      {getAwnPlusAppPrice(device, true) || '$49.99'}
                      <span>/year</span>
                    </div>
                  </button>
                  <button
                    className={`toggle-btn ${!annual ? 'active' : ''}`}
                    onClick={() => setAnnual(false)}
                  >
                    Monthly
                    <div>
                      {getAwnPlusAppPrice(device, false) || '$4.99'}
                      <span>/month</span>
                    </div>
                  </button>
                </div>
                <span className='discount'>Save 17%</span>
              </div>
            )}
            <button
              className='btn btn-primary'
              onClick={() => setStep(2)}
            >{btnLabel}</button>
            {/* <p className='trial'>Limited time free trial - ends {freeTrialUntil.format('MMMM Do, YYYY')}</p> */}
            <p className='trial'>Limited Time Offer - First Month Free!</p>
          </div>
          <div className='bottom'>
            <strong>Features</strong>
            <div className='group'>
              <div className='line'>
                <div className='label'>Map Layers</div>
                <div className='val'>
                  Air Quality, Snow Depth, Fires, Tropical Cyclones and Water Map <span>in addition to Standard Layers</span>
                </div>
              </div>
              <div className='line'>
                <div className='label'>Notifications</div>
                <div className='val'>
                  Configurable SMS Alerts (10 per day) <span>in addition to Push & Email Alerts</span>
                </div>
              </div>
              <div className='line'>
                <div className='label'>Hourly Forecast</div>
                <div className='val'>
                  10 Days
                </div>
              </div>
              <div className='line'>
                <div className='label'>Data History</div>
                <div className='val'>
                  3 Years
                </div>
              </div>
              <div className='line'>
                <div className='label'>Bonus Dashboard Tiles</div>
                <div className='val'>
                  Growing Degree Days, Heating Degree Days and Cooling Degree Days
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
  let show = ''
  if (paymentComplete) {
    message = 'Thank you for your purchase!'
    show = (
      <div className='payment-complete'>
        <div className='btns text-center'>
          <a className='btn-primary btn' onClick={() => doModal(null)}>Close</a>
        </div>
      </div>
    )
  } else if (user.plus && !plusUserSwitch) {
    let plan = ''
    const monthlyChosen = subIsMonthly(user.plus)
    if (/free/.test(user.plus.productName)) {
      plan = 'Free'
    } else if (awnPlusAnnualMonthlySwitchAvail(user)) {
      plan = (
        <div className='switch-term'>
          <AmbRadio
            selected={!monthlyChosen}
            label={(
              <>
                Annual <span className='save'>{getAwnPlusAppPrice(device, true) ? getAwnPlusAppPrice(device, true) + ' /yr' : '$49.99/yr'}</span>
              </>
            )}
            onClick={() => {
              if (!monthlyChosen) {
                return
              }
              setAnnual(true)
              setPlusUserSwitch(true)
              setStep(2)
            }}
          />
          <AmbRadio
            selected={monthlyChosen}
            label={(
              <>
                Monthly <span className='save'>{monthlyPrice}</span>
              </>
            )}
            onClick={() => {
              if (monthlyChosen) {
                return
              }
              setAnnual(false)
              setPlusUserSwitch(true)
              setStep(2)
            }}
          />
        </div>
      )
    } else if (monthlyChosen) {
      plan = 'Monthly'
    } else {
      plan = 'Annual'
    }
    let trialShow = (
      <h3>You are subscribed to AWN+!</h3>
    )
    if (user.plus.status === 'trialing') {
      trialShow = (
        <>
          <h3>You are using the AWN+ free trial!</h3>
          <p>Free trial until {moment(user.plus.currentPeriodEnd * 1000).format('M/D/YYYY')}</p>
        </>
      )
    }
    show = (
      <div className={classNames('awn-plus-member', { switchAvail: awnPlusAnnualMonthlySwitchAvail(user) })}>
        {trialShow}
        <div className='box'>
          <div className='table'>
            <div>
              <span>Subscription Plan</span>
              <span>AWN+</span>
            </div>
            <div className='options'>
              <span>Billing option</span>
              <span>{plan}</span>
            </div>
            <div>
              <span>{user.plus.cancelAtPeriodEnd ? 'Expires' : 'Renews'}</span>
              <span>{user.plus.currentPeriodEnd ? moment(user.plus.currentPeriodEnd * 1000).format('M/D/YYYY') : '∞'}</span>
            </div>
          </div>
        </div>
        <div className='btns'>
          <Link to='/account/#subscriptions' onClick={() => doModal(null)} className='btn btn-primary'>Manage Subscription</Link>
          <a className='btn' onClick={() => doModal(null)}>Close</a>
        </div>
      </div>
    )
  } else if (step === 1) {
    show = showPlans
  } else if (step === 2) {
    let proRate = ''
    if (!isApp() && plusUserSwitch) {
      proRate = annual
        ? (
          <p className='prorate'>Your account will be prorated for the remaining time on your current monthly subscription. This means we'll credit the unused portion of your monthly plan against the cost of the yearly plan. You'll only be charged the difference. Enjoy the savings that come with your yearly subscription!</p>
        ) : (
          <p className='prorate'>
            Since you're moving from a yearly to a monthly plan, we will prorate the change. This means we will calculate the unused portion of your yearly subscription and apply this as a credit to your account. This credit will offset the cost of your upcoming monthly bills until it is fully used. Enjoy the flexibility of your new monthly subscription!
          </p>
        )
    }
    show = (
      <SubscribeProcess
        user={user}
        subscription={`awn-plus-account-${term}`}
      >
        {showLegend && annual && <p>annual saving of 17% is equivalent to $4.16/month</p>}
        {proRate}
        <p className='privacy'>
          <a rel='noreferrer' href='https://nkhome.com/terms' target='_blank'>Terms of Service</a>
          <span> and </span>
          <a rel='noreferrer' href='https://nkhome.com/privacy' target='_blank'>Privacy Policy</a>
        </p>
      </SubscribeProcess>
    )
  }
  return (
    <div className='payment-plus-subscription-modal'>
      <div>
        <header className='awn-logo'>
          <h2>{message || 'Expanded Layers, Extended History, Precision Forecast'}</h2>
          {termType && <div className='term'>{termType} <span>Save up to 17% with an annual subscription!</span></div>}
        </header>
        {show}
      </div>
    </div>
  );
};

PlusSubscriptionModal.propTypes = {};
PlusSubscriptionModal.defaultProps = {};

PlusSubscriptionModal.displayName = 'PlusSubscriptionModal'

export default bindDeviceActions(PlusSubscriptionModal)
