import React, { PureComponent } from 'react';
import classNames from 'classnames'
import bindDeviceActions from './redux/bindDeviceActions'
import PropTypes from 'prop-types';
import { find, pipe, last, reduce, lensIndex, over, concat, prop, test, all, intersection, isArrayLike, merge, filter, flip, contains, path } from 'ramda'
import { ambient } from '../../common/lib/ambient-weather-common'
import { InView } from 'react-intersection-observer'
import { getHlAvg, getDeviceCoords, getDominantWindDir, summaryDocsForDates, containsOldDate, isSomething, isAdmin, isOver30Days, orderFields, FIELD_PRIORITY, padData, currentDeviceHasSummaries, convertUnitForUser, getLabel, getDisplayKeys, getInc, dataPending, fieldsEq, toggleArr, fmtDate, getDisplayKeysFromState, theDeviceIsMine, removeFromArrWith, fcontains, pathsChanged, canAccessOldData } from '../../common/ambient'
import DeviceDataGraphWrap from './DeviceDataGraphWrap'
import SummariesWrap from './SummariesWrap'
import CheckboxList from '../../components/CheckboxList'
import DatePicker from '../../components/DatePicker'
import bindAllActions from '../../common/bindAllActions';

class DeviceDataTableWrap extends PureComponent {
  static propTypes = {
    macAddress: PropTypes.string,
    device: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    deviceActions: PropTypes.object,
    onGraphClick: PropTypes.func,
    dates: PropTypes.array, // [start, end] moment objects, to pass to the graph
    shortGraphs: PropTypes.bool, // pass to graphs
    hideKeys: PropTypes.array
  };
  constructor(props) {
    super(props)
    this.retries = 0
    this.graphFilters = FIELD_PRIORITY 
    this.state = {
      retrying: false,
      mode: 'daily',
      page: 1,
      end: moment().endOf('day').valueOf(),
      start: moment().startOf('day').valueOf(),
      showTable: true,
      graphMode: true,
      firstFetch: false,
      activeFilters: window.localStorage && window.localStorage.activeFilters ? window.localStorage.activeFilters.split(",") : this.graphFilters.slice(0)
    }
    this.nextPage = ::this.nextPage
    this.prevPage = ::this.prevPage
    this.removeDataPoints = ::this.removeDataPoints
    this.downloadCsv = ::this.downloadCsv
    this.fetchDeviceData = ::this.fetchDeviceData
  }
  downloadCsv(data) {
    const { user, device } = this.props
    const convertUnit = convertUnitForUser(user)
    return () => {
      const fprop = flip(prop)
      const keys = concat(['dateutc'], getDisplayKeys(data).sort())
      const headerRow = 'Datetime' + keys.map(k => getLabel(k, device)).join(',')
      const dataRow = (obj) => {
        const getConvertedValue = (param) => {
          return pipe(
            fprop(obj),
            convertUnit(param)
          )(param)
        }
        const row = keys.map(getConvertedValue)
        const formattedRow = over(lensIndex(0), fmtDate('M/D/YYYY h:mm A'), row)
        return formattedRow.join(',')
      }
      const dataRows = data.map(dataRow).reverse()
      const csv = 'data:text/csv;charset=utf-8,' + headerRow + "\n" + dataRows.join("\n")
      const link = document.createElement('a')
      link.setAttribute('href', encodeURI(csv))
      link.setAttribute('download', 'ambient-weather.csv')
      document.body.appendChild(link)
      link.click()
    }
  }
  _modeForGraphs() {
    const { device } = this.props
    if (!currentDeviceHasSummaries(device)) return 'daily'
    const args = this.getFetchArgs()
    switch(args && args.res) {
      case 30:
        return 'weekly'
      case 240:
        return 'monthly'
      case 1440:
        return 'yearly'
      default:
        return 'daily' 
    }
  }
  _getDates(prevProps, prevState) {
    const propsToUse = prevProps || this.props
    const stateToUse = prevState || this.state
    return propsToUse.dates || stateToUse.summaryDates
  }
  getFetchArgs() {
    const { macAddress } = this.props
    const dates = this._getDates()
    if (!dates) return
    const ret = {
      macAddress
    }
    ret.start = dates[0].valueOf()
    ret.end = dates[1].valueOf()
    ret.limit = 2000
    const diff = ret.end - ret.start
    if (diff > 1000 * 60 * 60 * 24 * 30) { // more than a month show daily 
      ret.res = 1440
    } else if (diff > 1000 * 60 * 60 * 24 * 8) { // more than a week show every 4 hrs
      ret.res = 240
    } else if (diff > 1000 * 60 * 60 * 25) { // more than 25 hours show 30 mins
      ret.res = 30
    }
    return ret
  }
  prevPage() {
    const newPage = this.state.page + 1
    this.setState({
      page: newPage
    })
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'graphs', 'prev-page')
    }
  }
  nextPage() {
    const newPage = this.state.page - 1
    this.setState({
      page: newPage,
    })
    if (typeof ga !== 'undefined') {
      ga('send', 'event', 'graphs', 'next-page')
    }
  }
  removeDataPoints(rows) {
    if (window.confirm('Are you sure you want to delete ' + rows.length + ' row' + (rows.length === 1 ? '' : 's') + '?')) {
      this.props.deviceActions.removeDeviceData(rows)
    }
  }
  fetchDeviceData() {
    const hiRes = true
    const { fetchDeviceData, clearDataKey } = this.props.deviceActions
    const fetchArgs = this.getFetchArgs() 
    if (!fetchArgs) return

    const args = merge(fetchArgs, {
      dataKey: 'graphDataRefined'
    })
    fetchDeviceData(args)
      .then(() => {
        this.setState({
          firstFetch: true
        })
      })
    clearTimeout(this._refreshTout)
    this._refreshTout = setTimeout(this.fetchDeviceData, 1000 * 60 * 5)
  }
  _datesAreDifferent(prevProps, prevState) {
    const oldDates = this._getDates(prevProps, prevState) || []
    const dates = this._getDates() || []
    if (oldDates.length !== dates.length) return true
    return !oldDates.every((d, i) => d.isSame(dates[i]))
  }

  componentDidUpdate(prevProps, prevState) {
    const { device, user } = this.props
    const { graphDataRefined } = device
    // fetch refined data
    if (
      (
        !fieldsEq(['macAddress'], this.props, prevProps) // new device or...
        || !fieldsEq(['page', 'mode'], this.state, prevState) // new graph mode or...
        || this._datesAreDifferent(prevProps, prevState) // dates have changed
        || device.manualRefresh !== prevProps.device.manualRefresh // manual refresh
      )
      && !dataPending('graphDataRefined', device) // we're not already getting it
      && user.userChecked
      && !this._showSummaryGraphs() // we only want to show summaries
      ) {
      setTimeout(this.fetchDeviceData.bind(this), 100)
    }
    if (pathsChanged(this.props, prevProps, [['user', 'info', '_id']]) && user.info._id) {
      this.fetchDeviceData()
    }
    if (pathsChanged(this.props, prevProps, [['device', 'graphDataRefined']])
      || pathsChanged(this.state, prevState, [['summaryDates'], ['firstFetch']])
    ) {
      this._processFinalData()
    }
  }

  componentDidMount(){
    this.fetchDeviceData()
  }
  componentWillUnmount(){
    clearInterval(this._refreshTout)
  }

  _processFinalData() {
    const { graphMode, firstFetch } = this.state
    const { device, user } = this.props
    const { graphDataRefined, deviceSummaries } = device
    let finalData = graphDataRefined
    if (!firstFetch) return

    if (this.state.mode === 'daily' && isArrayLike(finalData)) {
      finalData = ambient.roundDataDateutc(5)(finalData)
    }

    if (isArrayLike(finalData) && this.getFetchArgs()) {
      // remove date points - testing
      /* const fstart = new Date(2017, 3, 17) 
       * const fend = new Date(2017, 3, 19) 
       * finalData = filter((d) => {
       *   return d.dateutc < fstart.getTime() || d.dateutc > fend.getTime()
       * }, finalData)*/
      // testing

      finalData = padData(this.getFetchArgs().res, finalData)
    }
    // show the summary graphs
    if (graphMode && this._showSummaryGraphs() && deviceSummaries) {
      const convertUnit = convertUnitForUser(user)
      finalData = summaryDocsForDates(moment, device, this._getDates(), deviceSummaries).map(summary => {
        const ret = {
          dateutc: summary.dateutc,
          summary
        }
        Object.keys(summary).forEach(param => {
          ret[param + 'Hl'] = [convertUnit(param, path([param, 'l'], summary)), convertUnit(param, path([param, 'h'], summary))]
          if (param === 'winddir') {
            ret.winddirAvg = getDominantWindDir(summary[param])
          } else {
            ret[param + 'Avg'] = convertUnit(param, getHlAvg(summary, param))
          }
          // we need an H param for the graphs
          if (contains(param, ['dailyrainin', 'maxdailygust', 'lightning_day'])) {
            ret[param + 'H'] = ret[param + 'Hl'][1]
          }
        })
        return ret 
      })
    }
    this.setState({
      finalData
    })
  }

  _showSummaryGraphs() {
    const dates = this._getDates()
    if (!dates[0] || !dates[1]) return false
    return dates[1].valueOf() - dates[0].valueOf() > 1000 * 60 * 60 * 24 * 7
    // return containsOldDate(this._getDates())
  }

  render() {
    const { mode, graphMode, page, activeFilters, finalData } = this.state
    const { device, user, macAddress, hideKeys, deviceActions, markerTime, onGraphClick, shortGraphs } = this.props
    const { clearHiresData } = deviceActions 
    const { deviceSummaries, graphDataGross, graphDataRefined, hiresData, fetchDeviceDataPending } = device

    const availableFilters = getDisplayKeysFromState(['graphDataGross', 'graphDataRefined', 'deviceSummaries'], this.props.device)

    const availFiltersHave = all(flip(contains)(availableFilters))
    if (availFiltersHave(['tempf', 'humidity'])) {
      availableFilters.push('dewPoint')
      availableFilters.push('feelsLike')
    }
    if (filter(test(/lightning/), availableFilters).length > 0) {
      availableFilters.push('lightning')
    }
    if (filter(test(/rain/), availableFilters).length > 0) {
      availableFilters.push('rain')
    }
    if (getDeviceCoords(device)) {
      availableFilters.push('sunMoon')
    }
    const currentGraphFilters = orderFields(intersection(availableFilters, this.graphFilters))
    console.log('currentGraphFilters', currentGraphFilters)

    const controls =
      <div className="controls clearfix">
        <div className="wrap">
          <ul className="graph-mode pagination">
            <li
              className={graphMode ? 'active' : ''}
            >
              <a
                tabIndex={-1}
                onClick={() => {
                  this.setState({ graphMode: true })
                }}
              >
                <i className="icon-graph" />
              </a>
            </li>
            <li
              className={!graphMode ? 'active' : ''}
            >
              <a
                tabIndex={-1}
                onClick={() => this.setState({ graphMode: false })}
              >
                <i className="icon-table" />
              </a>
            </li>
          </ul>
          <CheckboxList
            allFields={currentGraphFilters}
            activeFields={activeFilters}
            device={device}
            onChange={(f) => {
              const newActiveFilters = toggleArr(f, activeFilters)
              this.setState({
                activeFilters: newActiveFilters
              })
              window.localStorage.activeFilters = newActiveFilters.join(",")
            }}
          />
        </div>
        <DatePicker
          device={device}
          onChange={summaryDates => this.setState({ summaryDates })}
        />
      </div>

    let pendingMessage = 'fetching...'
    if (this.state.retrying) {
      pendingMessage = this.state.retrying
    }
    let show = ''
    if (graphMode) {
      show =
        <DeviceDataGraphWrap
          mode={this._modeForGraphs()}
          macAddress={macAddress}
          pending={fetchDeviceDataPending}
          activeGraphs={intersection(activeFilters, availableFilters)}
          hideKeys={hideKeys}
          data={finalData}
          user={user}
          device={device}
          shortGraphs={shortGraphs}
          markerTime={markerTime}
          onGraphClick={onGraphClick}
          deviceActions={deviceActions}
        />
    } else if (this.state.showTable) {
      show = <SummariesWrap
              dates={this._getDates()}
              activeFilters={removeFromArrWith(fcontains(hideKeys), activeFilters)}
             />
    }

    let cls = 'device-device-data-table-wrap'
    if (graphMode) {
      cls += ' graph'
    }
    return (
      <div className={classNames(cls, {
          tz: true 
        }, `gl-${(graphDataRefined || []).length}`)}>
        {controls}
        <div className="data-table">
          {show}
        </div>
      </div>
    );
  }
}

export default bindAllActions(DeviceDataTableWrap)

DeviceDataTableWrap.displayName = 'DeviceDataTableWrap'
