import { getStorage } from '../../../common/ambient'

const deviceI = getStorage('deviceI') > -1 ? parseInt(getStorage('deviceI'), 10) : 0
const initialState = {
  current: {},
  dataTableData: false,
  deviceI,
  deviceCache: {},
  getDeviceIdsPending: [],
  getDeviceFailures: [],
  saveCurrentPending: false,
  saveCurrentError: null,
  fetchDevicesPending: false,
  fetchDevicesError: null,
  removeDevicePending: false,
  removeDeviceError: null,
  checkMacAddressPending: false,
  checkMacAddressError: null,
  checkMacAddressSuccess: false,
  checkMacAddressDataExists: null,
  fetchDeviceDataPending: false,
  fetchDeviceDataKeysPending: [],
  fetchDeviceDataError: null,
  deviceDataAllDisplayKeys: [],
  deviceDataAllDataKeys: [],
  removeDeviceDataPending: false,
  removeDeviceDataError: null,
  patchPending: false,
  patchError: null,
  fetchAllDevicesPending: false,
  fetchAllDevicesError: null,
  allDevices: null,
  updateDeviceDataPending: false,
  updateDeviceDataError: null,
  fetchDevicePending: false,
  fetchDeviceError: null,
  fetchedDevices: [],
  manualRefresh: 3,
  getDevicePending: false,
  getDeviceError: null,
  dashboardDevice: null,
  fetchForecastPending: false,
  fetchForecastError: null,
  mapVisibleDevices: [],
  sidebar: false,
  mapLayer: '',
  focusDevice: null,
  hoverDevice: null,
  forecastCache: {},
};

export default initialState;
