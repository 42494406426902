import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types';
import { fetchFromBilling } from './billingAccess'
import { path } from 'ramda'
import Switch from 'react-switch'
import { Loader } from '../../components'
import bindAllActions from '../../common/bindAllActions'
import { isCellularDevice, hasCellularSubscription, displayPriceForSubscription, subIsIap, subIsIos, subStatusDisplay, awnPlusAnnualMonthlySwitchAvail, subIsIosDowngradedAnnual } from '.'
import { isAndroidApp, isApp, isIosApp } from '../../common/ambient'

// Should display a row for each case:
// * An active subscription, regardless of stationType.  This supports deleted stations that could be re-added.
// * StationType == KestrelMet6000.  This supports adding new subscription to a lapsed station.

function DisplaySubscription ({ user, device, userActions }) {
  const [subsUpdating, setSubsUpdating] = useState([])
  const userSubs = path(['info', 'payments', 'subscriptions'], user) || []
  const devices = path(['devices'], device) || []

  let atLeastOneRow = false
  if (userSubs.length > 0) atLeastOneRow = true
  const noSubscriptions = (
    <tr>
      <td colspan='6'>
        You currently have no subscriptions.
      </td>
    </tr>
  )
  // init
  useEffect(() => {
    userActions.refreshUser()
  }, [userActions])

  return (
    <div className='payment-display-subscription'>
      <div className='aw-fields'>
        <table>
          <thead>
            <th>Applies to</th>
            <th>Product</th>
            <th>Price</th>
            <th>Status</th>
            <th>Expires</th>
            <th>Auto-renew</th>
          </thead>
          <tbody>
            {devices.map((dev, i) => {
              const mac = path(['macAddress'], dev)
              const cellDeviceNeedsSubscription = (isCellularDevice(dev) && !hasCellularSubscription(user, mac))
              // const cellDeviceNeedsSubscription = (isCellularDevice(dev) && true)
              if (!cellDeviceNeedsSubscription) return null
              atLeastOneRow = true
              const currentDevice = dev // for naming consistency in CellPlanModal

              return (
                <tr key={i}>
                  <td>{mac}</td>
                  <td>KestrelMet Standard 1 Year Subscription</td>
                  <td>$49.99</td>
                  <td>--</td>
                  <td>--</td>
                  <td>
                    <button onClick={() => userActions.doModal({ type: 'cell-plan', data: { currentDevice } })} className='btn btn-primary btn-lg'>Add Plan</button>
                  </td>
                </tr>
              )
            })}

            {userSubs.map((sub, i) => {
              let autorenew = (
                <Switch
                  onChange={async () => {
                    if (subIsIap(sub)) {
                      if (isIosApp()) {
                        window.webkit.messageHandlers.showManageSubscriptions.postMessage({})
                      } else if (window.ANDROID) {
                        window.ANDROID.showManageSubscriptions(sub.googleProductId, path(['info', '_id'], user))
                      }
                    } else {
                      setSubsUpdating([...subsUpdating, i])
                      const cancel_at_period_end = !sub.cancelAtPeriodEnd
                      const res = await fetchFromBilling(`/subscriptions?userId=${path(['info', '_id'], user)}&macAddress=${sub.macAddress}&productName=${sub.productName}`, {
                        method: 'PUT',
                        body: JSON.stringify({
                          cancel_at_period_end
                        }),
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      })
                      const newUser = Object.assign({}, user.info)
                      newUser.payments.subscriptions[i].cancelAtPeriodEnd = cancel_at_period_end
                      await userActions.refreshUser(newUser)
                      setSubsUpdating(subsUpdating.filter(j => j !== i))
                    }
                  }}
                  checked={!sub.cancelAtPeriodEnd}
                />
              )
              if (subsUpdating.includes(i)) {
                autorenew = <Loader />
              } else if (subsUpdating.length > 0 || sub.status === 'free') {
                autorenew = <div className='placeholder' />
              } else if (!isApp() && subIsIap(sub)) {
                if (subIsIos(sub)) {
                  autorenew = <a href='https://apps.apple.com/account/subscriptions' target='_blank'>Manage in App Store</a>
                } else {
                  autorenew = <a href={`https://play.google.com/store/account/subscriptions?sku=${sub.googleProductId}&package=net.ambientweather.dashboard`} target='_blank'>Manage in Play Store</a>
                }
              }
              let name = sub.nickname
              if (subIsIosDowngradedAnnual(sub)) {
                name += ' (will downgrade to monthly at end of period)'
              }
              return (
                <tr key={i}>
                  <td>{sub.macAddress}</td>
                  <td>{name}</td>
                  <td>
                    {displayPriceForSubscription(sub)}
                    {/awn-plus/.test(sub.productName) &&
                      awnPlusAnnualMonthlySwitchAvail(user) &&
                      !subIsIosDowngradedAnnual(sub) &&
                      (
                        <a
                          className='change-plan'
                          onClick={() => userActions.doModal({ type: 'plus-subscription' })}
                        >
                          Change plan
                        </a>
                      )
                    }
                    {/awn-camera/.test(sub.productName) && (
                      <a
                        className='change-plan'
                        onClick={() => userActions.doModal({ type: 'enhanced-cam', data: { currentDevice: device.devices.find(d => d.macAddress === sub.macAddress) } })}
                      >
                        Change plan
                      </a>
                    )}
                  </td>
                  <td>{subStatusDisplay(sub)}</td>
                  <td>{moment(sub.currentPeriodEnd * 1000).format('MMM, D, YYYY')}</td>
                  <td className='switch'>{autorenew}</td>
                </tr>
              )
            })}
            {atLeastOneRow ? '' : noSubscriptions}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default bindAllActions(DisplaySubscription)

DisplaySubscription.propTypes = {}
DisplaySubscription.defaultProps = {}

DisplaySubscription.displayName = 'DisplaySubscription'
